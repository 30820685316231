<template>
    <el-drawer
        title="课程信息编辑"
        :visible.sync="openDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        ref="editDrawer"
        size="80%"
    >
        <div class="demo-drawer__content">
            <el-form ref="editForm" :model="info" label-suffix=":" label-width="140px" :rules="ruleValidate">
                <el-row :gutter="32">
                    <el-col :span="24">
                        <el-form-item label="工种类型">
                            <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.gnvqCatId" :gnvqId.sync="info.gnvqId"
                                        :levelId.sync="info.levelId"  @callback="changeGnvq"></GnvqSelect>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="12">
                        <el-form-item label="课程名称" prop="courseName">
                            <el-input v-model="info.courseName" placeholder="请输入课程名称" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="8">
                        <el-form-item label="课程图片">
                            <el-row>
                                <el-col :span="24">
                                    <SingleUploadImg @callback="handleUpload" :imageUrl="info.courseImgUrl"
                                                     :width="150" :height="100"></SingleUploadImg>
                                    <el-button type="success" size="mini" @click="toSelectClassImage">选择图片</el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="是否推荐" prop="recommend">
                                    <el-radio-group v-model="info.recommend">
                                        <el-radio :label="0">否</el-radio>
                                        <el-radio :label="1">是</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="序号" prop="orderNum">
                                <span slot="label">
                                    序号：
                                    <el-tooltip  content="显示的顺序，不控制可输入0" effect="light" placement="top">
                                        <i class="el-icon-question" style="color:red"></i>
                                    </el-tooltip>
                                </span>
                                    <el-input v-model="info.orderNum"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="是否可售卖" prop="canSale">
                                    <el-radio-group v-model="info.canSale">
                                        <el-radio :label="0">否</el-radio>
                                        <el-radio :label="1">是</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row :gutter="32" v-show="info.canSale===1">
                    <el-col :span="8">
                        <el-form-item label="课程原价" prop="coursePrice">
                            <el-input v-model="info.coursePrice" placeholder="请输入课程原价" >
                                <span slot="append">元</span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="当前售价" prop="salePrice">
                            <el-input v-model="info.salePrice" placeholder="请输入当前售价" >
                                <span slot="append">元</span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="购买后有效期" prop="periodDays">
                            <el-input v-model="info.periodDays" placeholder="请输入购买后有效期" >
                                <span slot="append">天</span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否可刷题" prop="canExercise">
                            <el-radio-group v-model="info.canExercise">
                                <el-radio :label="0">否</el-radio>
                                <el-radio :label="1">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否可模拟考试" prop="canExam">
                            <el-radio-group v-model="info.canExam">
                                <el-radio :label="0">否</el-radio>
                                <el-radio :label="1">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="刷题模考试卷" prop="examRuleId">
                            <el-input v-model="info.examRuleName" :readonly="true">
                                <el-button @click="toSelectExamRule" slot="append" icon="el-icon-search"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="8">
                        <el-form-item label="是否加入课程市场" prop="shopFlag">
                            <el-radio-group v-model="info.shopFlag">
                                <el-radio :label="0">否</el-radio>
                                <el-radio :label="1">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="课程市场单次价格" prop="timesAmount">
                            <el-input v-model="info.timesAmount" placeholder="请输入单次价格" >
                                <span slot="append">元/人次</span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="24">
                        <el-form-item label="课程简介" prop="courseAbs">
                            <el-input type="textarea" v-model="info.courseAbs" :rows="2" placeholder="请输入课程简介" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="24">
                        <el-divider content-position="left">课程详情</el-divider>
                        <editor ref="editor" :height="300"></editor>
                    </el-col>
                    <!--                        <el-form-item label="课程详情" prop="courseAbs">-->
                    <!--                            <el-input type="textarea" v-model="info.courseContent" :rows="6"/>-->
                    <!--                        </el-form-item>-->
                </el-row>
            </el-form>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="openDrawer = false">取消</el-button>
            <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
        </div>
        <ExamRuleSelect ref="examRuleSelect" @callback="ruleSelectCallback"></ExamRuleSelect>
        <ImageSelectDialog ref="imageSelectDialog" @callback="imageCallback"></ImageSelectDialog>
    </el-drawer>
</template>

<script>
import GnvqSelect from "@v/components/GnvqSelect.vue";
import SingleUploadImg from "@c/upload/SingleUploadImg.vue";
import editor from "@c/editor/editor.vue";
import ExamRuleSelect from "@v/course/components/ExamRuleSelect.vue";
import ImageSelectDialog from "@v/components/ImageSelectDialog.vue";

export default {
    components: {
        GnvqSelect,
        SingleUploadImg,
        editor,
        ExamRuleSelect,
        ImageSelectDialog
    },
    data () {
        const validateNumber = (rule, value, callback) => {
            if (!Number.isInteger(+value)) {
                callback(new Error('请输入数值'));
            } else {
                callback();
            }
        };
        const validateNumber2 = (rule, value, callback) => {
            let regPos = /^\d+(\.\d+)?$/; // 非负浮点数
            if (!regPos.test(value)) {
                callback(new Error('请输入数值'));
            } else {
                callback();
            }
        };

        return {
            accessToken: 'Bearer ' + this.appCookies.getCookieAccessToken(),
            info: {
                orderNum: 0,
                canSale: 1
            },
            openDrawer: false,
            ruleValidate: {
                gnvqCatId: [
                    { required: true, type: 'number', message: '请选择工种分类', trigger: 'blur' }
                ],
                gnvqId: [
                    { required: true, type: 'number', message: '请选择工种', trigger: 'blur' }
                ],
                // levelId: [
                //     { required: true, type: 'number', message: '请选择工种等级', trigger: 'blur' }
                // ],
                courseName: [
                    { required: true, message: '请输入课程名称', trigger: 'blur' }
                ],
                courseTime: [
                    { required: true, validator: validateNumber, message: '请输入课程时长', trigger: 'blur' },
                ],
                recommend: [
                    { required: true, type: 'number', message: '请选择是否推荐', trigger: 'change' }
                ],
                canSale: [
                    { required: true, type: 'number', message: '请选择是否可售卖', trigger: 'change' }
                ],
                orderNum: [
                    { validator: validateNumber, trigger: 'blur' }
                ],
                coursePrice: [
                    { required: true, validator: validateNumber2, message: '请输入课程原价', trigger: 'blur' }
                ],
                salePrice: [
                    { required: true, validator: validateNumber2, message: '请输入当前售价', trigger: 'blur' }
                ],
                periodDays: [
                    { required: true, validator: validateNumber, message: '请输入购买后有效期', trigger: 'blur' }
                ],
                shopFlag: [
                    { required: true, type: 'number', message: '请选择是否加入课程市场', trigger: 'change' }
                ],
            },
            courseId: '',
        };
    },
    methods: {
        open: function (courseId) {
            this.courseId = courseId;
            this.toEdit(courseId)
        },
        toAdd: function (gnvqCatId, gnvqId, levelId) {
            this.info = {
                orderNum: 0,
                gnvqCatId: gnvqCatId,
                gnvqId: gnvqId,
                levelId: levelId,
                examRuleName: '',
                canSale: 1,
                canExercise: 0,
                canExam: 0,
            };
            this.$nextTick(function() {
                this.$refs.editor.setHtml("");
            });
            this.openDrawer = true;
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    if (this.info.shopFlag===1) {
                        if (!this.info.timesAmount) {
                            this.$message({
                                message: '请输入课程市场单次交易价格',
                                showClose: true, offset: 50, type: 'error'
                            });
                            return
                        }
                    }
                    // if (!this.info.gnvqCatId || !this.info.gnvqId || !this.info.levelId) {
                    //     this.$Message.error({
                    //         content: '请选择工种信息',
                    //         top: 400,
                    //         duration: 3
                    //     });
                    //     return
                    // }
                    let url = "course/info/save";
                    this.info.courseContent = this.$refs.editor.getHtml();
                    this.info.orgId = this.appCookies.getCookieOID();
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieUNAME() ;
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieUNAME() ;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.openDrawer = false;
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                // this.initTable();
                                this.$emit("callback")
                                this.openDrawer = false;
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        toEdit: function (courseId) {
            this.apis.get('course/info/getById?courseId=' + courseId)
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data;
                        // this.getGnvqList();
                        // this.getLevelList();
                        this.$nextTick(function() {
                            this.$refs.editor.setHtml(this.info.courseContent);
                            // this.$refs.gnvqSelect.initData(this.info.gnvqCatId, this.info.gnvqId, this.info.levelId);
                        });
                        this.openDrawer = true;
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getUploadUrl: function () {
            return process.env.VUE_APP_API_HOST + 'system/file/uploadFile';
        },
        handleUpload (webPath, savePath,) {
            this.$set(this.info, 'courseImgUrl', webPath);
            this.$set(this.info, 'courseImg', savePath);
        },
        toSelectClassImage: function () {
            this.$refs.imageSelectDialog.open();
        },
        imageCallback: function (webPath, savePath) {
            this.$set(this.info, 'courseImgUrl', webPath);
            this.$set(this.info, 'courseImg', savePath);
        },
        changeGnvq: function (levelInfo) {
        },
        toSelectExamRule: function () {
            this.$refs.examRuleSelect.open(this.info.gnvqCatId, this.info.gnvqId, this.info.levelId);
        },
        ruleSelectCallback: function (ruleId, ruleName) {
            this.info.examRuleId = ruleId;
            this.info.examRuleName = ruleName;
        },
    }
}
</script>

<style scoped>

</style>
