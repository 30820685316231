<template>
    <el-dialog
        title="图片选择"
        :visible.sync="imageSelectDialog"
        :destroy-on-close="false"
        :append-to-body="true"
        width="1050px"
        :close-on-click-modal="false">
        <el-row :gutter="16">
            <el-col :span="24">
                <el-tabs value="m1" type="card" @tab-click="changeTab">
                    <el-tab-pane label="课程图片" name="m1">
                        <el-row class="row-container">
                            <el-col :span="24">
                                <el-form :inline="true">
                                    <el-form-item label="">
                                        <el-input v-model="queryCourseParams.keyword" placeholder="查询关键字"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" icon="el-icon-search"  @click="refreshCourseImage">查询</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="24">
                                <div class="image-list">
                                        <el-card v-for="(item, idx) in courseImageList" :key="idx"
                                                 :body-style="{padding: '0px', width: '180px' }">
                                            <el-image fit="fill" lazy class="image" :src="item.courseImgUrl">
                                                <div slot="placeholder" class="image-slot">
                                                    加载中<span class="dot">...</span>
                                                </div>
                                            </el-image>
                                            <div style="padding: 0 10px 0 0; text-align: right">
                                                <el-button type="text" class="button" @click="selectImage(item.courseImg, item.courseImgUrl)">使用</el-button>
                                            </div>
                                        </el-card>
                                    <div class="empty-cell" v-for="item in (5 - courseImageList.length%5)" v-if="courseImageList.length%5 > 0" :key="item"></div>
                                </div>
                                <!-- 分页组件 -->
                                <Pagination v-show="queryCourseParams.totalRecord>0" :total="queryCourseParams.totalRecord"
                                            :page.sync="queryCourseParams.pageNumber" :limit.sync="queryCourseParams.pageSize"
                                            @pagination="queryCourseImage"/>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="班级图片" name="m2">
                        <el-row class="row-container">
                            <el-col :span="24">
                                <el-form :inline="true">
                                    <el-form-item label="">
                                        <el-input v-model="queryClassParams.keyword" placeholder="查询关键字"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" icon="el-icon-search"  @click="refreshClassImage">查询</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="24">
                                <div class="image-list">
                                    <el-card v-for="(item, idx) in classImageList" :key="idx"
                                             :body-style="{padding: '0px', width: '180px' }">
                                        <el-image fit="fill" lazy class="image" :src="item.classImgUrl">
                                            <div slot="placeholder" class="image-slot">
                                                加载中<span class="dot">...</span>
                                            </div>
                                        </el-image>
                                        <div style="padding: 0 10px 0 0; text-align: right">
                                            <el-button type="text" class="button" @click="selectImage(item.classImg, item.classImgUrl)">使用</el-button>
                                        </div>
                                    </el-card>
                                    <div class="empty-cell" v-for="item in (5 - classImageList.length%5)" v-if="classImageList.length%5 > 0" :key="item"></div>
                                </div>
                                <!-- 分页组件 -->
                                <Pagination v-show="queryClassParams.totalRecord>0" :total="queryClassParams.totalRecord"
                                            :page.sync="queryClassParams.pageNumber" :limit.sync="queryClassParams.pageSize"
                                            @pagination="queryClassImage"/>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="视频库图片" name="m3">
                        <el-row class="row-container">
                            <el-col :span="24">
                                <el-form :inline="true">
                                    <el-form-item label="">
                                        <el-input v-model="queryVideoParams.keyword" placeholder="查询关键字"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" icon="el-icon-search"  @click="refreshVideoImage">查询</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="24">
                                <div class="image-list">
                                    <el-card v-for="(item, idx) in videoImageList" :key="idx"
                                             :body-style="{padding: '0px', width: '180px' }">
                                        <el-image fit="fill" lazy class="image" :src="item.videoImgUrl">
                                            <div slot="placeholder" class="image-slot">
                                                加载中<span class="dot">...</span>
                                            </div>
                                        </el-image>
                                        <div style="padding: 0 10px 0 0; text-align: right">
                                            <el-button type="text" class="button" @click="selectImage(item.videoImg, item.videoImgUrl)">使用</el-button>
                                        </div>
                                    </el-card>
                                    <div class="empty-cell" v-for="item in (5 - videoImageList.length%5)" v-if="videoImageList.length%5 > 0" :key="item"></div>
                                </div>
                                <!-- 分页组件 -->
                                <Pagination v-show="queryVideoParams.totalRecord>0" :total="queryVideoParams.totalRecord"
                                            :page.sync="queryVideoParams.pageNumber" :limit.sync="queryVideoParams.pageSize"
                                            @pagination="queryVideoImage"/>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="音频库图片" name="m4">
                        <el-row class="row-container">
                            <el-col :span="24">
                                <el-form :inline="true">
                                    <el-form-item label="">
                                        <el-input v-model="queryAudioParams.keyword" placeholder="查询关键字"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" icon="el-icon-search"  @click="refreshAudioImage">查询</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="24">
                                <div class="image-list">
                                    <el-card v-for="(item, idx) in audioImageList" :key="idx"
                                             :body-style="{padding: '0px', width: '180px' }">
                                        <el-image fit="fill" lazy class="image" :src="item.audioImgUrl">
                                            <div slot="placeholder" class="image-slot">
                                                加载中<span class="dot">...</span>
                                            </div>
                                        </el-image>
                                        <div style="padding: 0 10px 0 0; text-align: right">
                                            <el-button type="text" class="button" @click="selectImage(item.audioImg, item.audioImgUrl)">使用</el-button>
                                        </div>
                                    </el-card>
                                    <div class="empty-cell" v-for="item in (5 - audioImageList.length%5)" v-if="audioImageList.length%5 > 0" :key="item"></div>
                                </div>
                                <!-- 分页组件 -->
                                <Pagination v-show="queryAudioParams.totalRecord>0" :total="queryAudioParams.totalRecord"
                                            :page.sync="queryAudioParams.pageNumber" :limit.sync="queryAudioParams.pageSize"
                                            @pagination="queryAudioImage"/>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="图文库图片" name="m5">
                        <el-row class="row-container">
                            <el-col :span="24">
                                <el-form :inline="true">
                                    <el-form-item label="">
                                        <el-input v-model="queryArticleParams.keyword" placeholder="查询关键字"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" icon="el-icon-search"  @click="refreshArticleImage">查询</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="24">
                                <div class="image-list">
                                    <el-card v-for="(item, idx) in articleImageList" :key="idx"
                                             :body-style="{padding: '0px', width: '180px' }">
                                        <el-image fit="fill" lazy class="image" :src="item.articleImgUrl">
                                            <div slot="placeholder" class="image-slot">
                                                加载中<span class="dot">...</span>
                                            </div>
                                        </el-image>
                                        <div style="padding: 0 10px 0 0; text-align: right">
                                            <el-button type="text" class="button" @click="selectImage(item.articleImg, item.articleImgUrl)">使用</el-button>
                                        </div>
                                    </el-card>
                                    <div class="empty-cell" v-for="item in (5 - articleImageList.length%5)" v-if="articleImageList.length%5 > 0" :key="item"></div>
                                </div>
                                <!-- 分页组件 -->
                                <Pagination v-show="queryArticleParams.totalRecord>0" :total="queryArticleParams.totalRecord"
                                            :page.sync="queryArticleParams.pageNumber" :limit.sync="queryArticleParams.pageSize"
                                            @pagination="queryArticleImage"/>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="imageSelectDialog = false">关 闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
import Pagination from '@/components/Pagination';
export default {
    components: {
        Pagination
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在分析计算，请稍等',
            imageSelectDialog: false,
            queryCourseParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                currentCount: 0,
                keyword: '',
                orgId: this.appCookies.getCookieTOID()
            },
            courseImageList: [],
            queryClassParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                currentCount: 0,
                keyword: '',
                orgId: this.appCookies.getCookieTOID()
            },
            classImageList: [],
            queryVideoParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                currentCount: 0,
                keyword: '',
                orgId: this.appCookies.getCookieTOID()
            },
            videoImageList: [],
            queryAudioParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                currentCount: 0,
                keyword: '',
                orgId: this.appCookies.getCookieTOID()
            },
            audioImageList: [],
            queryArticleParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                currentCount: 0,
                keyword: '',
                orgId: this.appCookies.getCookieTOID()
            },
            articleImageList: []
        }
    },
    mounted() {
    },
    methods: {
        open: function () {
            this.imageSelectDialog = true;
            this.refreshCourseImage();
        },
        changeTab: function (e) {
            if (e.name === 'm1') {
                this.refreshCourseImage();
            } else if (e.name === 'm2') {
                this.refreshClassImage();
            } else if (e.name === 'm3') {
                this.refreshVideoImage();
            } else if (e.name === 'm4') {
                this.refreshAudioImage();
            } else if (e.name === 'm5') {
                this.refreshArticleImage();
            }
        },
        queryCourseImage: function () {
            this.apis.postForm('course/info/queryImage', this.queryCourseParams)
                .then((res) => {
                    if (res.code === 1) {
                        // this.queryCourseParams.currentCount = (this.queryCourseParams.pageNumber + 1) * this.queryCourseParams.pageSize
                        this.queryCourseParams.totalRecord = res.count;
                        this.courseImageList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        refreshCourseImage: function () {
            this.queryCourseParams.pageNumber = 0;
            this.courseImageList = []
            this.queryCourseImage();
        },
        scrollLoadCourse: function () {
            if (this.queryCourseParams.currentCount <= this.queryCourseParams.totalRecord) {
                this.queryCourseParams.pageNumber = this.queryCourseParams.pageNumber + 1;
                this.queryCourseImage();
            }
        },
        queryClassImage: function () {
            this.apis.postForm('course/class/queryImage', this.queryClassParams)
                .then((res) => {
                    if (res.code === 1) {
                        // this.queryClassParams.currentCount = (this.queryClassParams.pageNumber + 1) * this.queryClassParams.pageSize
                        this.queryClassParams.totalRecord = res.count;
                        this.classImageList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        refreshClassImage: function () {
            this.queryClassParams.pageNumber = 0;
            this.classImageList = []
            this.queryClassImage();
        },
        queryVideoImage: function () {
            this.apis.postForm('course/video/queryImage', this.queryVideoParams)
                .then((res) => {
                    if (res.code === 1) {
                        // this.queryClassParams.currentCount = (this.queryClassParams.pageNumber + 1) * this.queryClassParams.pageSize
                        this.queryVideoParams.totalRecord = res.count;
                        this.videoImageList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        refreshVideoImage: function () {
            this.queryVideoParams.pageNumber = 0;
            this.videoImageList = []
            this.queryVideoImage();
        },
        queryAudioImage: function () {
            this.apis.postForm('course/audio/queryImage', this.queryAudioParams)
                .then((res) => {
                    if (res.code === 1) {
                        // this.queryClassParams.currentCount = (this.queryClassParams.pageNumber + 1) * this.queryClassParams.pageSize
                        this.queryAudioParams.totalRecord = res.count;
                        this.audioImageList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        refreshAudioImage: function () {
            this.queryAudioParams.pageNumber = 0;
            this.audioImageList = []
            this.queryAudioImage();
        },
        queryArticleImage: function () {
            this.apis.postForm('course/article/queryImage', this.queryArticleParams)
                .then((res) => {
                    if (res.code === 1) {
                        // this.queryClassParams.currentCount = (this.queryClassParams.pageNumber + 1) * this.queryClassParams.pageSize
                        this.queryArticleParams.totalRecord = res.count;
                        this.articleImageList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        refreshArticleImage: function () {
            this.queryArticleParams.pageNumber = 0;
            this.articleImageList = []
            this.queryArticleImage();
        },
        scrollLoadClass: function () {
            if (this.queryClassParams.currentCount <= this.queryClassParams.totalRecord) {
                this.queryClassParams.pageNumber = this.queryClassParams.pageNumber + 1;
                this.queryClassImage();
            }
        },
        selectImage: function (savePath, webPath) {
            this.$emit('callback', webPath, savePath);
            this.imageSelectDialog = false;
        },
    }
}
</script>

<style scoped lang="scss">
    .image-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 0;
    }
    .empty-cell {
        overflow: hidden;
        content: '';
        width: 180px;
        padding: 20px 0;
    }
    //.image-list:after {
    //    content: "";
    //    width: 100px;
    //}
    ::v-deep .el-card {
        margin: 10px 0;
    }
    .image {
        width: 180px;
        height: 140px;
    }
</style>
