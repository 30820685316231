<template>
    <el-dialog
        title="考试刷题规则选择"
        :visible.sync="examRuleSelectModal"
        :destroy-on-close="false"
        :close-on-click-modal="false"
        :append-to-body="true"
        width="70%"
        :fullscreen="false"
    >
        <div style="min-height:500px">
            <el-row :gutter="8" class="row-container">
<!--                <el-col :span="5">-->
<!--                    <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">-->
<!--                        <div slot="header" class="clearfix">-->
<!--                            <i class="el-icon-s-operation"></i>-->
<!--                            <span>工种分类</span>-->
<!--                        </div>-->
<!--                        <GnvqTree ref="gnvqTree" @callback="toQuery"></GnvqTree>-->
<!--                    </el-card>-->
<!--                </el-col>-->
                <!-- 列表主体 -->
                <el-col :span="24">
                    <el-row class="query-container">
                        <el-col>
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:120px"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                style="width: 100%;"
                                ref="videoQueryTable"
                                :indent="20"
                            >
                                <el-table-column label="试题名称" prop="ruleName" min-width="200px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="答题时长" prop="examTime" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="总分数" prop="examScore" min-width="80px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="工种" prop="gnvqName" min-width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="等级" prop="levelName" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="基础题类别" prop="baseCatName" min-width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="多选题(专业/分数)" min-width="150px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <span>{{row.mcqCount + '/' + row.mcqScore}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="单选题(基础/专业/分数)" min-width="190px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <span>{{row.baseScqCount + '/' + row.scqCount + '/' + row.scqScore}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="判断题(基础/专业/分数)" min-width="190px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <span>{{row.baseJcqCount + '/' + row.jcqCount + '/' + row.jcqScore}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="论述题(专业/分数)" min-width="150px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <span>{{row.dcqCount + '/' + row.dcqScore}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="140" fixed="right" class-name="small-padding">
                                    <template slot-scope="{row}">
                                        <el-button plain size="mini" @click="singleSelect(row)">选择</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="examRuleSelectModal = false">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import Pagination from '@/components/Pagination';
    // import GnvqTree from "@v/components/GnvqTree";
    export default {
        components: {
            Pagination,
            // GnvqTree,
        },
        filters: {
            useStatusFilter(status) {
                const statusMap = {
                    0: '禁用',
                    1: '可用',
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    1: '已删除',
                    0: '正常',
                };
                return statusMap[status]
            },
            videoTypeFilter(status) {
                const statusMap = {
                    1: '基础课',
                    2: '专业课',
                };
                return statusMap[status]
            },
            videoTypeColorFilter(status) {
                const statusMap = {
                    1: '',
                    2: 'success'
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                examRuleSelectModal: false,
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: 0,
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    examItemCat: '',
                    baseCatId: '',
                    useStatus: 1,
                    orgId: this.appCookies.getCookieTOID(),
                },
                //列表数据
                tableData: [],
                tableLoading: false,
            };
        },
        mounted () {
        },
        methods: {
            open: function (gnvqCatId, gnvqId, levelId) {
                this.queryParams.gnvqCatId = gnvqCatId;
                this.queryParams.gnvqId = gnvqId;
                this.queryParams.levelId = levelId;
                this.examRuleSelectModal = true;
                //console.log(this.queryParams.gnvqCatId, this.queryParams.gnvqId, this.queryParams.levelId);
                this.initTable();
            },
            singleSelect: function (row) {
                this.$emit('callback', row.ruleId, row.ruleName);
                this.examRuleSelectModal = false;
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('course/examPaperRule/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            toQuery: function (data) {
                this.cellIndex = '-1';
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                if (data.type === 'CAT') {
                    this.queryParams.gnvqCatId = data.id;
                } else if (data.type === 'INFO') {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.id;
                } else {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.parentId;
                    this.queryParams.levelId = data.id;
                }
                this.refreshTable()
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
