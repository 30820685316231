<template>
    <div>
        <el-select filterable v-model="new_GroupId" placeholder="请选择" @change="changeGroup">
            <el-option v-show="editable" label="添加分组" :value="-1">
                <div>
                    <i class="el-icon-circle-plus-outline" style="color: #1890ff"></i>
                    <span style="margin-left: 2px">添加分组</span>
                </div>
            </el-option>
            <el-option v-show="!editable" label="全部分组" value=""></el-option>
            <el-option label="默认分组" :value="0"></el-option>
            <el-option
                v-for="item in groupList"
                :key="item.groupId"
                :label="item.groupName"
                :value="item.groupId">
                <div style="display: flex; flex-direction: row">
                    <span style="flex: 1">{{item.groupName}}</span>
                    <el-popover
                        placement="right"
                        width="80px"
                        trigger="hover"
                        v-if="editable">
                        <div>
                            <el-button type="primary" circle size="mini" icon="el-icon-edit" @click.stop="toEditGroup(item)"></el-button>
                            <el-button type="danger" circle size="mini" icon="el-icon-delete" @click.stop="toDeleteGroup(item.groupId)"></el-button>
                        </div>
                        <div slot="reference">
                            <el-button type="text" icon="el-icon-setting"></el-button>
                        </div>
                    </el-popover>
                </div>
            </el-option>
        </el-select>
        <el-dialog
            title="编辑分组"
            :visible.sync="addGroupDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="300px">
            <el-form ref="addGroupForm">
                <el-row>
                    <el-col :span="20" :offset="2">
                        <el-form-item label="" prop="groupName">
                            <el-input v-model="info.groupName" placeholder="请输入分组名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addGroupDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveGroup">确认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        apiPrefix: {
            type: String,
            default: ''
        },
        editable: {
            type: Boolean,
            default: false
        },
        groupId: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        baseCatId: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        gnvqId: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        // levelId: {
        //     type: Number,
        //     default: 0
        // },
        levelId: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
    },
    data() {
        return {
            groupList: '',
            addGroupDialog: false,
            info: {
                groupId: '',
                groupName: ''
            },
            new_GroupId: '',
        }
    },
    watch: {
        baseCatId: {
            handler(val) {
                if (val) {
                    this.getGroupList()
                }
            },
            deep: true,
            immediate: true
        },
        levelId: {
            handler(val) {
                if (val) {
                    this.getGroupList()
                }
            },
            deep: true,
            immediate: true
        },
        groupId: {
            handler(val) {
                if (val!=='') {
                    this.new_GroupId = val
                } else {
                    if (this.editable) {
                        this.new_GroupId = 0
                    } else {
                        this.new_GroupId = ''
                    }

                }
            },
            deep: true,
            immediate: true
        },
        new_GroupId(val) {
            if (val !== -1) {
                this.$emit('update:groupId', val)
            }
        },
    },
    computed: {
        // innerGroupId: function () {
        //     return this.groupId;
        // }
    },
    mounted() {
        this.$nextTick(function () {
        });
    },
    methods: {
        getGroupList: function () {
            let data = {
                baseCatId: this.baseCatId,
                gnvqId: this.gnvqId,
                levelId: this.levelId,
                orgId: this.appCookies.getCookieOID()
            };
            let url = this.apiPrefix + "/getList"
            this.apis.postForm(url, data)
                .then((res) => {
                    console.log(res)
                    if (res.code === 1) {
                        this.groupList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        changeGroup: function (value) {
            // if (value === -1) {
            //     this.info = {
            //         groupName: "",
            //         groupId: ""
            //     }
            //     this.addGroupDialog = true;
            // }
            if (value === -1) {
                if (this.levelId || this.baseCatId) {
                    this.info = {
                        groupName: "",
                        groupId: ""
                    }
                    this.addGroupDialog = true;
                } else {
                    this.new_GroupId = 0
                    this.$message({
                        message: "请选择所属等级",
                        showClose: true,
                        offset: 50,
                        type: 'error'
                    });
                }
            }
        },
        toEditGroup: function (item) {
            this.info = item;
            this.addGroupDialog = true;
        },
        saveGroup: function () {
            if (!this.info.groupName) {
                this.$message({
                    message: "请输入组名",
                    showClose: true,
                    offset: 50,
                    type: 'error'
                });
                return
            }
            this.info.baseCatId = this.baseCatId;
            this.info.gnvqId = this.gnvqId;
            this.info.levelId = this.levelId;
            this.info.orgId = this.appCookies.getCookieOID();
            this.info.createBy = this.appCookies.getCookieUID();
            this.info.createByname = this.appCookies.getCookieTNAME();
            this.info.modifyBy = this.appCookies.getCookieUID();
            this.info.modifyByname = this.appCookies.getCookieTNAME();
            let url = this.apiPrefix + '/save'
            this.apis.postJson(url, this.info)
                .then((res) => {
                    if (res.code === 1) {
                        if (this.info.groupId === "") {
                            this.groupList.push({
                                groupId: res.data,
                                groupName: this.info.groupName
                            });
                            this.new_GroupId = res.data;
                        }
                        // else {
                        //     this.changeGroupName(this.info.groupId, this.info.groupName);
                        // }
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
            this.addGroupDialog = false;
        },
        changeGroupName: function (groupId, groupName) {
            for (let i = 0; i < this.groupList.length; i++) {
                if (this.groupList[i].groupId === groupId) {
                    this.groupList[i].groupName = groupName;
                    break;
                }
            }
        },
        toDeleteGroup: function (groupId) {
                let title = '确定要删除此条数据？';
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        groupId: groupId,
                        deleteStatus: 1,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    let url = this.apiPrefix + '/delete'
                    this.apis.postForm(url, data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.getGroupList();
                                if (groupId === this.new_GroupId) {
                                    this.new_GroupId = 0;
                                }
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
    },
}
</script>

<style lang="scss" scoped>
    /deep/ .el-popover{
        min-width: 100px !important;
        width: 100px !important;
    }
</style>

