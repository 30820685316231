<template>
    <div style="background: #FFFFFF" class="select-tree">
        <el-select :popper-append-to-body="false" filterable
            ref="selectTree"
            v-model="selectName"
            placeholder="请选择...">
            <el-option :value="selectValue" style="height: auto">
                <el-tree
                    ref="tree"
                    :data="dataList"
                    default-expand-all
                    :node-key="nodeKey"
                    empty-text="无数据"
                    :props="treeProps"
                    :expand-on-click-node="false"
                    highlight-current
                    @node-click="handleNodeClick"
                />
            </el-option>
        </el-select>
    </div>
</template>
<script>
export default {
    props: {
        treeData: {
            type: Array,
            default() {
                return [];
            }
        },
        treeProps: {
            type: Object,
            default() {
                return {
                    /** 唯一标识 **/
                    value: 'categoryId',
                    /** 标签显示 **/
                    label: 'categoryName',
                    /** 子级 **/
                    children: "children"
                };
            }
        },
        nodeKey: {
            type: [String, Number],
            default: 'categoryId'
        },
        selectId: {
            type: [String, Number],
            default: ''
        },
        onlySelectLeaf: {
            type: Boolean,
            default: true
        },
    },
    watch: {
        selectId: {
            deep: true,
            immediate: true,
            handler (val) {
                this.selectValue = val;
                this.echoData()
            }
        },
        selectValue: {
            deep: true,
            immediate: true,
            handler (val) {
                this.$emit('update:selectId', val)
            }
        },
        treeProps: {
            deep: true,
            handler (val) {
            }
        },
        treeData: {
            deep: true,
            immediate: true,
            handler (val) {
                this.dataList = JSON.parse(JSON.stringify(val));
                let defaults = {}
                defaults[this.treeProps.value] = 0;
                defaults[this.treeProps.label] = '默认';
                this.dataList.splice(0, 0, defaults)
            }
        },
    },
    data() {
        return {
            dataList: [],
            selectValue: 0,
            selectName: '',
            data: [
                {
                    id: 1,
                    name: "一级 1",
                    children: [
                        {
                            id: 4,
                            name: "一级 1-1",
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        //节点点击事件
        handleNodeClick(data, node){
            if (this.onlySelectLeaf && !node.isLeaf) {
                return;
            }
            // this.selectValue= data.categoryId;
            // this.selectName= data.categoryName;
            this.selectValue= data[this.treeProps.value];
            this.selectName= data[this.treeProps.label];
            // 选择器执行完成后，使其失去焦点隐藏下拉框的效果
            this.$refs.selectTree.blur();
        },
        // 数据集回显
        echoData() {
            this.$nextTick(() => {
                if (this.selectId === null) return;
                if (!this.dataList) return;
                this.$refs.tree.setCurrentKey(this.selectValue); // 设置节点高亮
                let node = this.$refs.tree.getCurrentNode();
                console.log(node)
                this.selectName= node[this.treeProps.label];
                this.$forceUpdate()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep {
        .el-scrollbar__wrap {
            margin: 0;
        }
        .el-select-dropdown__item {
            padding: 0 0 0 0 !important;
        }
        .el-select-dropdown__list {
            background-color: #FFFFFF !important;
        }
        .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
            background-color: #FFFFFF !important;
        }
    }
</style>
