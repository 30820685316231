<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col>
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>教师列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col>
                            <el-form :inline="true">
                                <el-form-item label="关键字">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                                </el-form-item>
                                <el-form-item label="状态">
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="职称" prop="jobTitleId">
                                    <el-select v-model="queryParams.jobTitleId">
                                        <el-option value="">全部</el-option>
                                        <el-option v-for="item in dictList" :value="item.dictId" :label="item.dictName"  :key="item.dictId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="状态">
                                    <el-select v-model="queryParams.recommend" placeholder="请选择">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="推荐" value="1"></el-option>
                                        <el-option label="不推荐" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row :gutter="32" class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="#" type="index" width="50PX"></el-table-column>
                                <el-table-column label="教师编号" prop="teacherCode" min-width="130PX" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="教师姓名" prop="teacherName" min-width="130px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="教师照片" prop="headImgUrl" min-width="100px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <el-avatar v-if="row.headImgUrl" shape="square" size="small" :src="row.headImgUrl"></el-avatar>
                                    </template>
                                </el-table-column>
                                <el-table-column label="职称" prop="jobTitleName" min-width="170px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="职务描述" prop="jobTitleDesc" min-width="170px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="是否推荐" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-switch
                                            v-model="row.recommend"
                                            :active-value="1"
                                            :inactive-value="0"
                                            active-color="#2d8cf0"
                                            inactive-color="#efefef"
                                            @change="toRecommend(row)">
                                        </el-switch>
                                    </template>
                                </el-table-column>
                                <el-table-column label="排序号" prop="orderNum" min-width="60px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作人" prop="modifyByname" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作时间" prop="modifyTime" min-width="200px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作" align="center" width="150" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.teacherId)">修改</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.teacherId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.teacherId, 0)">恢复</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            title="信息"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="60%">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="教师编号" prop="teacherCode">
                            <el-input v-model="info.teacherCode"></el-input>
                        </el-form-item>
                        <el-form-item label="教师姓名" prop="teacherName">
                            <el-input v-model="info.teacherName"></el-input>
                        </el-form-item>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="教师照片">
                                    <SingleUploadImg @callback="handleUpload" :imageUrl="info.headImgUrl"
                                                     :width="100" :height="100"></SingleUploadImg>
                                </el-form-item>
                            </el-col>
                            <el-col :span="16">
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="职称" prop="jobTitleId">
                                            <el-select v-model="info.jobTitleId">
                                                <el-option value="">全部</el-option>
                                                <el-option v-for="item in dictList" :value="item.dictId" :label="item.dictName"  :key="item.dictId"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-form-item label="职务描述" prop="jobTitleDesc">
                                            <el-input v-model="info.jobTitleDesc"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-form-item label="是否推荐" prop="recommend" >
                                            <el-radio-group v-model="info.recommend">
                                                <el-radio :label="0">否</el-radio>
                                                <el-radio :label="1">是</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>

                        <el-form-item label="教师简介" prop="teacherAbs">
                            <el-input type="textarea" v-model="info.teacherAbs" :rows="6" ></el-input>
                        </el-form-item>
                        <el-form-item label="序号" prop="orderNum">
                          <span slot="label">
                              序号：
                              <el-tooltip  content="显示的顺序，不控制可输入0" effect="light" placement="top">
                                <i class="el-icon-question" style="color:red"></i>
                              </el-tooltip>
                          </span>
                            <el-input v-model="info.orderNum">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import SingleUploadImg from "@c/upload/SingleUploadImg";
    export default {
        components: {
            Pagination,
            Breadcrumb,
            SingleUploadImg,
        },
        filters: {
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger'
                };
                return statusMap[status]
            },
        },
        data () {
            const validateNumber = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('输入序号'));
                } else if (!Number.isInteger(+value)) {
                    callback(new Error('请输入数值'));
                } else {
                    callback();
                }
            };
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '教师管理'
                    }
                ],
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    recommend: '',
                    jobTitleId: '',
                    orgId: this.appCookies.getCookieOID()
                },
                //列表数据
                tableData: [],
                tableLoading: false,
                //动态下拉菜单
                dictTypeList: [],
                //编辑、新增表单
                editDialog: false,
                info: {
                    orderNum: 0,
                    jobTitleId: ''
                },
                //表单验证规则
                ruleValidate: {
                    // 基本信息
                    teacherName: [
                        { required: true, message: '请输入教师姓名', trigger: 'blur' }
                    ],
                    jobTitleId: [
                        { required: true, message: '请选择职称', trigger: 'blur' }
                    ],
                    orderNum: [
                        { validator: validateNumber, trigger: 'blur' }
                    ]
                },
                dictList: []
            };
        },
        mounted () {
            this.initTable();
            this.getDictList();
        },
        computed: {
        },
        methods: {
            getDictList: function () {
                this.apis.get('system/dict/getList?typeCode=0008')
                    .then((res) => {
                        if (res.code === 1) {
                            this.dictList = res.data || []
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('course/teacher/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            toAdd: function () {
                this.info = {
                    orderNum: 0,
                    jobTitleId: ''
                };
                this.editDialog = true;
                // this.$refs.editForm.resetFields();
            },
            //上传图片组件callback方法
            handleUpload (webPath, savePath) {
                this.$set(this.info, 'headImgUrl', webPath);
                this.$set(this.info, 'headImg', savePath);
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "course/teacher/save";
                    this.info.orgId = this.appCookies.getCookieOID();
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDialog = false;
                                this.initTable();
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
            },
            toEdit: function (keyId) {
                this.apis.get('course/teacher/getById?teacherId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDialog = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (id, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        teacherId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('course/teacher/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            //改变图文可用状态
            toRecommend: function (row) {
                //console.log(row)
                let data = {
                    teacherId: row.teacherId,
                    recommend: row.recommend,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('course/teacher/updRecommend', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
