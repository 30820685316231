<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 工种分类 -->
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>工种等级分类</span>
                    </div>
                    <GnvqTree ref="gnvqTree" @callback="treeSelectChange"></GnvqTree>
                </el-card>
            </el-col>
            <!-- 列表主体 -->
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>课程列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true"  label-suffix=":">
                                <el-form-item label="关键字">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:120px"></el-input>
                                </el-form-item>
                                <el-form-item label="发布状态">
                                    <el-select v-model="queryParams.publishStatus" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="未发布" value="0"></el-option>
                                        <el-option label="已发布" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="是否推荐">
                                    <el-select v-model="queryParams.recommend" placeholder="是否推荐" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="否" value="0"></el-option>
                                        <el-option label="是" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button type="text"  @click="showMore=!showMore">更多条件</el-button>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row class="query-container" v-show="showMore">
                        <el-col :span="24">
                            <el-form :inline="true"  label-suffix=":">
                                <el-form-item label="课程来源">
                                    <el-select v-model="queryParams.courseFrom" placeholder="请选择" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="自建" value="SELF"></el-option>
                                        <el-option label="购买" value="BUY"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="作废状态">
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-el-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :show-header="false"
                            >
                                <el-table-column>
                                    <template slot-scope="{row}">
                                        <div class="table-row">
                                            <div class="left" @click="openCourse(row.courseId)">
                                                <img class="img" :src="row.courseImgUrl"/>
                                            </div>
                                            <div class="center">
                                                <div class="header">
                                                    <div class="item">
                                                        <span class="value strong">{{ row.courseName }}</span>
                                                    </div>
                                                    <div class="item">
                                                        <span class="label">工种：</span>
                                                        <span class="value">{{row.gnvqName}}</span>
                                                    </div>
                                                    <div class="item">
                                                        <span class="label">等级：</span>
                                                        <span class="value">{{ row.levelName }}</span>
                                                    </div>
                                                    <div class="item">
                                                        <span class="label">来源：</span>
                                                        <span class="value">{{ row.courseFrom === 'SELF' ? '自建' : '购买' }}</span>
                                                    </div>
                                                </div>
                                                <div class="content">
                                                    <div class="item-info">
                                                        <div class="item">
                                                            <span class="label">课程时长：</span>
                                                            <span class="value">{{ row.courseTime }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">有效期：</span>
                                                            <span class="value">{{ row.periodDays + '天' }}</span>
                                                        </div>
                                                        <div class="item" v-if="row.courseFrom === 'BUY'">
                                                            <span class="label">可用次数：</span>
                                                            <span class="value">{{ row.lastTimes + '次' }}</span>
                                                        </div>

                                                    </div>
                                                    <div class="item-info">
                                                        <div class="item">
                                                            <span class="label">课程原价：</span>
                                                            <span class="value">{{ row.coursePrice }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">当前售价：</span>
                                                            <span class="value">{{ row.salePrice }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">是否可售卖：</span>
                                                            <span class="value">{{ row.canSale | canSaleFilter}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="item-info">
                                                        <div class="item">
                                                            <span class="label">发布状态：</span>
                                                            <span class="value">
                                                                <el-switch
                                                                    v-model="row.publishStatus"
                                                                    :active-value="1"
                                                                    :inactive-value="0"
                                                                    active-color="#2d8cf0"
                                                                    inactive-color="#efefef"
                                                                    @change="toRelease(row)">
                                                                </el-switch>
                                                            </span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">推荐：</span>
                                                            <span class="value">
                                                                <el-switch
                                                                    v-model="row.recommend"
                                                                    :active-value="1"
                                                                    :inactive-value="0"
                                                                    active-color="#2d8cf0"
                                                                    inactive-color="#efefef"
                                                                    @change="toRecommend(row)">
                                                                </el-switch>
                                                            </span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">状态：</span>
                                                            <el-tag :type="row.deleteStatus | colorFilter">
                                                                {{ row.deleteStatus  | deleteStatusFilter}}
                                                            </el-tag>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="footer"></div> -->
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="120" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <div v-if="row.courseFrom === 'SELF'" class="oper-col">
                                            <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.courseId)">编辑 </el-button>
                                            <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.courseId, 0)">恢复</el-button>
                                            <el-button v-if="row.deleteStatus===0" type="text" size="mini" @click="editDetailV2(row)">内容编排</el-button>
                                            <el-dropdown size="mini" @command="dropdownOperate">
                                                <span class="el-dropdown-link">
                                                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                                                </span>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item v-if="row.deleteStatus===0" :command="dropdownValue('delete', row)">删除</el-dropdown-item>
                                                    <el-dropdown-item v-if="row.deleteStatus===0" :command="dropdownValue('copy', row)">复制</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </div>
                                        <div v-if="row.courseFrom === 'BUY'" class="oper-col">
                                            <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEditBuy(row.courseId)">编辑 </el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <!-- 新增、编辑课程信息侧边栏 -->
        <el-drawer
            title="课程管理"
            :visible.sync="openDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <el-form ref="editForm" :model="info" label-suffix=":" label-width="140px" :rules="ruleValidate">
                    <el-row :gutter="32">
                        <el-col :span="24">
                            <el-form-item label="工种类型">
                                <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.gnvqCatId" :gnvqId.sync="info.gnvqId"
                                        :levelId.sync="info.levelId"  @callback="changeGnvq"></GnvqSelect>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="12">
                            <el-form-item label="课程名称" prop="courseName">
                                <el-input v-model="info.courseName" placeholder="请输入课程名称" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="8">
                            <el-form-item label="课程图片">
                                <el-row>
                                    <el-col :span="24">
                                        <SingleUploadImg @callback="handleUpload" :imageUrl="info.courseImgUrl"
                                                         :width="150" :height="100"></SingleUploadImg>
                                        <el-button type="success" size="mini" @click="toSelectClassImage">选择图片</el-button>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                        </el-col>
                        <el-col :span="16">
                            <el-row :gutter="8">
                                <el-col :span="12">
                                    <el-form-item label="是否推荐" prop="recommend">
                                        <el-radio-group v-model="info.recommend">
                                            <el-radio :label="0">否</el-radio>
                                            <el-radio :label="1">是</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="序号" prop="orderNum">
                                <span slot="label">
                                    序号：
                                    <el-tooltip  content="显示的顺序，不控制可输入0" effect="light" placement="top">
                                        <i class="el-icon-question" style="color:red"></i>
                                    </el-tooltip>
                                </span>
                                        <el-input v-model="info.orderNum"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="是否可售卖" prop="canSale">
                                        <el-radio-group v-model="info.canSale">
                                            <el-radio :label="0">否</el-radio>
                                            <el-radio :label="1">是</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-show="info.canSale===1">
                        <el-col :span="8">
                            <el-form-item label="课程原价" prop="coursePrice">
                                <el-input v-model="info.coursePrice" placeholder="请输入课程原价" >
                                    <span slot="append">元</span>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="当前售价" prop="salePrice">
                                <el-input v-model="info.salePrice" placeholder="请输入当前售价" >
                                    <span slot="append">元</span>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="购买后有效期" prop="periodDays">
                                <el-input v-model="info.periodDays" placeholder="请输入购买后有效期" >
                                    <span slot="append">天</span>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="是否可刷题" prop="canExercise">
                                <el-radio-group v-model="info.canExercise">
                                    <el-radio :label="0">否</el-radio>
                                    <el-radio :label="1">是</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="是否可模拟考试" prop="canExam">
                                <el-radio-group v-model="info.canExam">
                                    <el-radio :label="0">否</el-radio>
                                    <el-radio :label="1">是</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="刷题模考试卷" prop="examRuleId">
                                <el-input v-model="info.examRuleName" :readonly="true">
                                    <el-button @click="toSelectExamRule" slot="append" icon="el-icon-search"></el-button>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="8">
                            <el-form-item label="是否加入课程市场" prop="shopFlag">
                                <el-radio-group v-model="info.shopFlag">
                                    <el-radio :label="0">否</el-radio>
                                    <el-radio :label="1">是</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="单次价格" prop="timesAmount">
                                <el-input v-model="info.timesAmount" placeholder="请输入单次价格" >
                                    <span slot="append">元/人次</span>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="24">
                            <el-form-item label="课程简介" prop="courseAbs">
                                <el-input type="textarea" v-model="info.courseAbs" :rows="2" placeholder="请输入课程简介" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="24">
                            <el-divider content-position="left">课程详情</el-divider>
                            <editor ref="editor" :height="300"></editor>
                        </el-col>
<!--                        <el-form-item label="课程详情" prop="courseAbs">-->
<!--                            <el-input type="textarea" v-model="info.courseContent" :rows="6"/>-->
<!--                        </el-form-item>-->
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="openDrawer = false">取消</el-button>
                <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
            </div>
        </el-drawer>
        <!-- 内容编排侧边栏 -->
        <el-drawer
            title="内容编排"
            :visible.sync="detailDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <CourseDetailEditor ref="courseDetailEditor" :id="courseId"></CourseDetailEditor>
            <!-- <CourseDetailEditorV2 ref="courseDetailEditor" :id="courseId"></CourseDetailEditorV2> -->
            <div class="demo-drawer__footer">
                <el-button @click="detailDrawer = false">取消</el-button>
                <el-button  type="primary" @click="detailDrawer = false">确认</el-button>
            </div>
        </el-drawer>
        <!-- 内容编排侧边栏V2 -->
        <CourseDetailEditorV3 ref="courseDetailEditorV3" :id="courseId"></CourseDetailEditorV3>
        <ExamRuleSelect ref="examRuleSelect" @callback="ruleSelectCallback"></ExamRuleSelect>
        <ImageSelectDialog ref="imageSelectDialog" @callback="imageCallback"></ImageSelectDialog>
        <CourseBuyEditDrawer ref="courseBuyEditDrawer" @callback="initTable"></CourseBuyEditDrawer>
        <CourseSelfEditDrawer ref="courseSelfEditDrawer" @callback="initTable"></CourseSelfEditDrawer>
        <CourseDetailDrawer ref="courseDetailDrawer"></CourseDetailDrawer>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqTree from "@v/components/GnvqTree";
    import GnvqSelect from "@v/components/GnvqSelect";
    import SingleUploadImg from "@c/upload/SingleUploadImg";
    import CourseDetailEditor from "./components/CourseDetailEditor";
    import CourseDetailEditorV3 from "./components/CourseDetailEditorV3";
    import editor from '../../components/editor/editor'
    import ExamRuleSelect from "./components/ExamRuleSelect"
    import ImageSelectDialog from "../components/ImageSelectDialog"
    import CourseBuyEditDrawer from "./components/CourseBuyEditDrawer"
    import CourseSelfEditDrawer from "./components/CourseSelfEditDrawer"
    import CourseDetailDrawer from "@v/shop/components/CourseDetailDrawer.vue";

    export default {
        components: {
            Breadcrumb,
            Pagination,
            GnvqTree,
            GnvqSelect,
            SingleUploadImg,
            CourseDetailEditor,
            CourseDetailEditorV3,
            editor,
            ExamRuleSelect,
            ImageSelectDialog,
            CourseBuyEditDrawer,
            CourseSelfEditDrawer,
            CourseDetailDrawer
        },
        filters: {
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger',
                };
                return statusMap[status]
            },
            canSaleFilter(status) {
                const statusMap = {
                    0: '否',
                    1: '是',
                };
                return statusMap[status]
            },
            colorFilter1(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success',
                };
                return statusMap[status]
            },
        },
        data () {
            const validateNumber = (rule, value, callback) => {
                if (!Number.isInteger(+value)) {
                    callback(new Error('请输入数值'));
                } else {
                    callback();
                }
            };
            const validateNumber2 = (rule, value, callback) => {
                let regPos = /^\d+(\.\d+)?$/; // 非负浮点数
                if (!regPos.test(value)) {
                    callback(new Error('请输入数值'));
                } else {
                    callback();
                }
            };

            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '课程管理'
                    }
                ],
                accessToken: 'Bearer ' + this.appCookies.getCookieAccessToken(),
                cellIndex: '',
                showMore: false,
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    recommend: '',
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    courseFrom: '',
                    orgId: this.appCookies.getCookieOID()
                },
                tableData: [],
                info: {
                    orderNum: 0,
                    canSale: 1
                },
                gnvqCatList: [],
                gnvqList: [],
                levelList: [],
                teacherList: [],
                teacherFinding: false,
                tableLoading: false,
                openDrawer: false,
                videoModal: false,
                ruleValidate: {
                    gnvqCatId: [
                        { required: true, type: 'number', message: '请选择工种分类', trigger: 'blur' }
                    ],
                    gnvqId: [
                        { required: true, type: 'number', message: '请选择工种', trigger: 'blur' }
                    ],
                    // levelId: [
                    //     { required: true, type: 'number', message: '请选择工种等级', trigger: 'blur' }
                    // ],
                    courseName: [
                        { required: true, message: '请输入课程名称', trigger: 'blur' }
                    ],
                    courseTime: [
                        { required: true, validator: validateNumber, message: '请输入课程时长', trigger: 'blur' },
                    ],
                    recommend: [
                        { required: true, type: 'number', message: '请选择是否推荐', trigger: 'change' }
                    ],
                    canSale: [
                        { required: true, type: 'number', message: '请选择是否可售卖', trigger: 'change' }
                    ],
                    orderNum: [
                        { validator: validateNumber, trigger: 'blur' }
                    ],
                    coursePrice: [
                        { required: true, validator: validateNumber2, message: '请输入课程原价', trigger: 'blur' }
                    ],
                    salePrice: [
                        { required: true, validator: validateNumber2, message: '请输入当前售价', trigger: 'blur' }
                    ],
                    periodDays: [
                        { required: true, validator: validateNumber, message: '请输入购买后有效期', trigger: 'blur' }
                    ],
                    shopFlag: [
                        { required: true, type: 'number', message: '请选择是否加入课程市场', trigger: 'change' }
                    ],
                },
                playerOptions: {
                    // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    aspectRatio: '16:9',
                    // width: document.documentElement.clientWidth, // 默认课程全屏时的最大宽度
                    autoplay: false, // 是否自动播放
                    muted: false, // 是否默认静音
                    language: 'zh-CN',
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载课程数据。auto浏览器选择最佳行为,立即开始加载课程（如果浏览器支持）
                    loop: false, // 导致课程一结束就重新开始。
                    playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4",
                        // mp4
                        src: "",
                        // webm
                        // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
                    }], // 课程地址
                    poster: "", // 封面
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        fullscreenToggle: true  // 全屏按钮
                    }
                },
                gnvqTreeData: [],
                detailDrawer: false,
                detailDrawerV2: false,
                courseId: '',
            };
        },
        mounted () {
            this.initTable();
            this.$nextTick(function() {
                this.getGnvqTreeData();
                // this.getGnvqCatList();
            });
        },
        computed: {
            player() {
                return this.$refs.videoPlayer.player
            },
            playsinline () {
                let ua = navigator.userAgent.toLocaleLowerCase()
                // x5内核
                if (ua.match(/tencenttraveler/) !== null || ua.match(/qqbrowse/) !== null) {
                    return false
                } else {
                    // ios端
                    return true
                }
            }
        },
        methods: {
            cellClick: function(index) {
                this.cellIndex = index;
                this.queryParams.videoType = index;
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                this.refreshTable();
            },
            renderContent (h, { root, node, data }) {
                return h('span', {
                        style: {
                            display: 'inline-block',
                            width: '100%',
                        }
                    },
                    [
                        h('span', [
                            h('Icon', {
                                props: {
                                    type: 'ios-list-box-outline',
                                    size: '16'
                                },
                                style: {
                                    marginRight: '8px'
                                }
                            }),
                            h('span', data.title)
                        ])
                    ]);
            },
            treeSelectChange: function (data) {
                this.cellIndex = '-1';
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                if (data.type === 'CAT') {
                    this.queryParams.gnvqCatId = data.id;
                } else if (data.type === 'INFO') {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.id;
                } else {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.parentId;
                    this.queryParams.levelId = data.id;
                }
                this.refreshTable()
            },
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('course/info/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            pIndexChange: function (page) {
                this.queryParams.pageNumber = page - 1;
                this.initTable();
            },
            pSizeChange: function (size) {
                this.queryParams.pageNumber = 0;
                this.queryParams.pageSize = size;
                this.initTable();
            },
            toAdd: function () {
                this.$refs.courseSelfEditDrawer.toAdd(this.queryParams.gnvqCatId, this.queryParams.gnvqId, this.queryParams.levelId)
                // this.info = {
                //     orderNum: 0,
                //     gnvqCatId: this.queryParams.gnvqCatId,
                //     gnvqId: this.queryParams.gnvqId,
                //     levelId: this.queryParams.levelId,
                //     examRuleName: '',
                //     canSale: 1,
                //     canExercise: 0,
                //     canExam: 0,
                // };
                // this.$nextTick(function() {
                //     this.$refs.editor.setHtml("");
                // });
                // this.openDrawer = true;
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        if (this.info.shopFlag===1) {
                            if (!this.info.timesAmount) {
                                this.$message({
                                    message: '请输入课程市场单次交易价格',
                                    showClose: true, offset: 50, type: 'error'
                                });
                                return
                            }
                        }
                        // if (!this.info.gnvqCatId || !this.info.gnvqId || !this.info.levelId) {
                        //     this.$Message.error({
                        //         content: '请选择工种信息',
                        //         top: 400,
                        //         duration: 3
                        //     });
                        //     return
                        // }
                        let url = "course/info/save";
                        this.info.courseContent = this.$refs.editor.getHtml();
                        this.info.orgId = this.appCookies.getCookieOID();
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieUNAME() ;
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieUNAME() ;
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.openDrawer = false;
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.initTable();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            toEdit: function (courseId) {
                // this.apis.get('course/info/getById?courseId=' + courseId)
                //     .then((res) => {
                //         if (res.code === 1) {
                //             this.info = res.data;
                //             // this.getGnvqList();
                //             // this.getLevelList();
                //             this.$nextTick(function() {
                //                 this.$refs.editor.setHtml(this.info.courseContent);
                //                 // this.$refs.gnvqSelect.initData(this.info.gnvqCatId, this.info.gnvqId, this.info.levelId);
                //             });
                //             this.openDrawer = true;
                //         } else {
                //             this.info = {}
                //         }
                //     }).catch(err => {
                //     console.log(err);
                // });
                this.$refs.courseSelfEditDrawer.open(courseId)
            },
            toEditBuy: function (courseId) {
                this.$refs.courseBuyEditDrawer.open(courseId)
            },
            toDelete: function (id, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        courseId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('course/info/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            copy: function (id, deleteStatus) {
                let title = '确定要复制此课程';
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        courseId: id,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('course/info/copy', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            change: function (status) {
                console.log(status)
                this.$Message.info('开关状态：' + status);
            },
            toRelease: function (row) {
                let data = {
                    courseId: row.courseId,
                    publishStatus: row.publishStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('course/info/publish', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.initTable();
                        } else {
                            this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toRecommend: function (row) {
                let data = {
                    courseId: row.courseId,
                    recommend: row.recommend,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('course/info/recommend', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.initTable();
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            getUploadUrl: function () {
                return process.env.VUE_APP_API_HOST + 'system/file/uploadFile';
            },
            handleUpload (webPath, savePath,) {
                this.$set(this.info, 'courseImgUrl', webPath);
                this.$set(this.info, 'courseImg', savePath);
            },
            toSelectClassImage: function () {
                this.$refs.imageSelectDialog.open();
            },
            imageCallback: function (webPath, savePath) {
                this.$set(this.info, 'courseImgUrl', webPath);
                this.$set(this.info, 'courseImg', savePath);
            },
            getGnvqTreeData: function () {
                this.apis.get('gnvq/info/getTreeList?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        if (res.code === 1) {
                            this.gnvqTreeData = res.data;
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            changeGnvq: function (levelInfo) {
            },
            showVideo: function () {
                this.playerOptions['sources'][0].src = this.info.videoPathUrl;
                this.videoModal = true;
            },
            editDetail: function (courseId) {
                //this.courseId = courseId;
                this.detailDrawer = true;
                this.$nextTick(function() {
                    this.$refs.courseDetailEditor.init(courseId);
                });
            },
            editDetailV2: function (row) {
                this.$refs.courseDetailEditorV3.open(row.courseId, row.gnvqCatId, row.gnvqId, row.levelId);
            },
            dropdownValue: function (type, row) {
                return {
                    'type': type, //key 是你自己想要的字段，值自己定义
                    'data': row
                };
            },
            dropdownOperate: function (command) {
                console.log(command)
                if (command.type==='delete') {
                    this.toDelete(command.data.courseId, 1);
                } else if (command.type==='arrange') {
                    this.editDetail(command.data.courseId);
                } else if (command.type==='copy') {
                    this.copy(command.data.courseId);
                }
            },
            toSelectExamRule: function () {
                this.$refs.examRuleSelect.open(this.info.gnvqCatId, this.info.gnvqId, this.info.levelId);
            },
            ruleSelectCallback: function (ruleId, ruleName) {
                this.info.examRuleId = ruleId;
                this.info.examRuleName = ruleName;
            },
            openCourse: function (courseId) {
                this.$refs.courseDetailDrawer.open(courseId)
            }
        }
    }
</script>

<style lang="scss" scoped>
.table-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 10px 0;
    .left {
        height: 100px;
        width: 140px;
        flex-shrink:0;
        img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
            cursor: pointer;
        }
    }

    .center {
        height: 100px;
        flex: 1;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: #999999 dotted 1px;
            margin-bottom: 6px;
            .item {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 14px;
                margin-bottom: 6px;
                .label {
                    text-align: left;
                    font-weight: bold;
                    min-width: 60px;
                }
                .value {
                    text-align: left;
                    padding-left: 10px;
                    min-width: 60px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .strong {
                    font-weight: bold;
                }
            }
        }

        .content {
            flex: 1;
            display: flex;
            flex-direction: row;
            //justify-content: space-between;
            .item-info {
                height: 100%;
                padding: 0 10px;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                .item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .label {
                        min-width: 60px;
                        text-align: right;
                        font-weight: bold;
                    }
                    .value {
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .strong {
                        font-weight: bold;
                    }
                }
            }
        }

        .footer {

        }
    }
}
.oper-col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
}
</style>
