<template>
    <el-dialog
        title="视频选择"
        :visible.sync="videoSelectModal"
        :destroy-on-close="false"
        :close-on-click-modal="false"
        :append-to-body="true"
        width="100%"
        :fullscreen="true"
    >
        <div style="height:100vh">
            <el-row :gutter="8" class="row-container">
                <el-col :span="5">
                    <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                        <div slot="header" class="clearfix">
                            <i class="el-icon-s-operation"></i>
                            <span>工种分类</span>
                        </div>
                        <GnvqStoreTree ref="gnvqTree" @callback="toQuery"></GnvqStoreTree>
                        <!-- <GnvqTree ref="gnvqTree" @callback="toQuery" :expandedKeys="expandedKeys"></GnvqTree> -->
                    </el-card>
                </el-col>
                <!-- 列表主体 -->
                <el-col :span="19">
                    <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="视频列表" name="video">
                            <el-row class="query-container">
                                <el-col>
                                    <el-form :inline="true">
                                        <el-form-item label="">
                                            <el-input v-model="videoQueryParams.keyword" placeholder="查询关键字"></el-input>
                                        </el-form-item>
                                        <el-form-item label="" v-show="videoQueryParams.levelId || videoQueryParams.baseCatId">
                                            <GroupEditorSelect ref="videoGroupSelect"
                                                apiPrefix="/course/video/group"
                                                :editable="false"
                                                :groupId.sync="videoQueryParams.groupId"
                                                :baseCatId="videoQueryParams.baseCatId"
                                                :gnvqId="videoQueryParams.gnvqId"
                                                :levelId="videoQueryParams.levelId">
                                            </GroupEditorSelect>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-button type="primary" icon="el-icon-search"  @click="refreshVideoTable">查询</el-button>
                                        </el-form-item>
                                        <el-button style="float:right" round type="primary" v-show="selectType!=='SINGLE'" @click="mutiSelect">确认选择</el-button>
                                    </el-form>
                                </el-col>
                            </el-row>
                            <el-row :gutter="32" class="table-container">
                                <el-col>
                                    <el-table
                                        v-loading="videoTableLoading"
                                        :data="videoTableData"
                                        fit
                                        stripe
                                        highlight-current-row
                                        style="width: 100%;"
                                        height="calc(100vh - 300px)"
                                        ref="videoQueryTable"
                                        @selection-change="handleSelectionChange1"
                                        :indent="20"
                                    >
                                        <el-table-column
                                            type="selection"
                                            width="50">
                                        </el-table-column>
                                        <el-table-column label="视频名称" prop="videoName" min-width="180px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                        <el-table-column label="工种" prop="gnvqName" min-width="130px" align="left"></el-table-column>
                                        <el-table-column label="等级" prop="levelName" min-width="100px" align="left"></el-table-column>
                                        <el-table-column label="分组" prop="groupName" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                        <el-table-column label="操作" align="center" width="140" fixed="right" class-name="small-padding">
                                            <template slot-scope="{row}">
                                                <el-button v-if="selectType!=='MUTI' && row.useStatus === 1" plain size="mini" @click="singleSelect(row)">选择</el-button>
                                                <el-button v-if="row.videoPath !== ''" plain size="mini" @click="toPlayAliyun(row)">预览</el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <Pagination v-show="videoQueryParams.totalRecord>0" :total="videoQueryParams.totalRecord"
                                                :page.sync="videoQueryParams.pageNumber" :limit.sync="videoQueryParams.pageSize"
                                                @pagination="initVideoTable"/>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="音频列表" name="audio">
                            <el-row class="query-container">
                                <el-col>
                                    <el-form :inline="true">
                                        <el-form-item label="">
                                            <el-input v-model="audioQueryParams.keyword" placeholder="查询关键字"></el-input>
                                        </el-form-item>
                                        <el-form-item label="" v-show="audioQueryParams.levelId || audioQueryParams.baseCatId">
                                            <GroupEditorSelect ref="audioGroupSelect"
                                                apiPrefix="/course/audio/group"
                                                :editable="false"
                                                :groupId.sync="audioQueryParams.groupId"
                                                :baseCatId="audioQueryParams.baseCatId"
                                                :gnvqId="audioQueryParams.gnvqId"
                                                :levelId="audioQueryParams.levelId">
                                            </GroupEditorSelect>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-button type="primary" icon="el-icon-search"  @click="refreshAudioTable">查询</el-button>
                                        </el-form-item>
                                        <el-button style="float:right" round type="primary" v-show="selectType!=='SINGLE'" @click="mutiSelect">确认选择</el-button>
                                    </el-form>
                                </el-col>
                            </el-row>
                            <el-row :gutter="32" class="table-container">
                                <el-col>
                                    <el-table
                                        v-loading="audioTableLoading"
                                        :data="audioTableData"
                                        fit
                                        stripe
                                        highlight-current-row
                                        style="width: 100%;"
                                        height="calc(100vh - 300px)"
                                        ref="audioQueryTable"
                                        @selection-change="handleSelectionChange2"
                                        :indent="20"
                                    >
                                        <el-table-column
                                            type="selection"
                                            width="50">
                                        </el-table-column>
                                        <el-table-column label="音频名称" prop="audioName" min-width="180px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                        <el-table-column label="工种" prop="gnvqName" min-width="130px" align="left"></el-table-column>
                                        <el-table-column label="等级" prop="levelName" min-width="100px" align="left"></el-table-column>
                                        <el-table-column label="分组" prop="groupName" min-width="80px" align="left"></el-table-column>
                                        <el-table-column label="操作" align="center" width="140" fixed="right" class-name="small-padding">
                                            <template slot-scope="{row}">
                                                <el-button v-if="selectType!=='MUTI' && row.useStatus === 1" plain size="mini" @click="singleSelect(row)">选择</el-button>
                                                <el-button v-if="row.aliyunUrl !== ''" plain size="mini" @click="toPlayAudio(row)">预览</el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <Pagination v-show="audioQueryParams.totalRecord>0" :total="audioQueryParams.totalRecord"
                                                :page.sync="audioQueryParams.pageNumber" :limit.sync="audioQueryParams.pageSize"
                                                @pagination="initAudioTable"/>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="图文列表" name="article">
                            <el-row class="query-container">
                                <el-col>
                                    <el-form :inline="true">
                                        <el-form-item label="">
                                            <el-input v-model="articleQueryParams.keyword" placeholder="查询关键字"></el-input>
                                        </el-form-item>
                                        <el-form-item label="" v-show="articleQueryParams.levelId || articleQueryParams.baseCatId">
                                            <GroupEditorSelect ref="articleGroupSelect"
                                                apiPrefix="/course/article/group"
                                                :editable="false"
                                                :groupId.sync="articleQueryParams.groupId"
                                                :baseCatId="articleQueryParams.baseCatId"
                                                :gnvqId="articleQueryParams.gnvqId"
                                                :levelId="articleQueryParams.levelId">
                                            </GroupEditorSelect>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-button type="primary" icon="el-icon-search"  @click="refreshArticleTable">查询</el-button>
                                        </el-form-item>
                                        <el-button style="float:right" round type="primary" v-show="selectType!=='SINGLE'" @click="mutiSelect">确认选择</el-button>
                                    </el-form>
                                </el-col>
                            </el-row>
                            <el-row :gutter="32" class="table-container">
                                <el-col>
                                    <el-table
                                        v-loading="articleTableLoading"
                                        :data="articleTableData"
                                        fit
                                        stripe
                                        highlight-current-row
                                        style="width: 100%;"
                                        height="calc(100vh - 300px)"
                                        ref="articleQueryTable"
                                        @selection-change="handleSelectionChange3"
                                        :indent="20"
                                    >
                                        <el-table-column
                                            type="selection"
                                            width="50">
                                        </el-table-column>
                                        <el-table-column label="图文名称" prop="articleName" min-width="180px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                        <el-table-column label="工种" prop="gnvqName" min-width="130px" align="left"></el-table-column>
                                        <el-table-column label="等级" prop="levelName" min-width="100px" align="left"></el-table-column>
                                        <el-table-column label="分组" prop="groupName" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                        <el-table-column label="操作" align="center" width="140" fixed="right" class-name="small-padding">
                                            <template slot-scope="{row}">
                                                <el-button v-if="selectType!=='MUTI' && row.useStatus === 1" plain size="mini" @click="singleSelect(row)">选择</el-button>
                                                <el-button v-if="row.deleteStatus===0" plain size="mini" @click="toReview(row.articleId)">预览</el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <Pagination v-show="articleQueryParams.totalRecord>0" :total="articleQueryParams.totalRecord"
                                                :page.sync="articleQueryParams.pageNumber" :limit.sync="articleQueryParams.pageSize"
                                                @pagination="initArticleTable"/>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                    <!-- <i class="el-icon-s-operation"></i> -->
                    <!-- <span>视频列表</span> -->
                    <div style="float: right; padding: 0px 0">
                        <!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
                        <!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                    </div>
                </el-col>
            </el-row>
            <el-dialog
                title="视频预览"
                :visible.sync="playVideoDialog"
                :destroy-on-close="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                width="800px"
            >
                <el-row>
                    <el-col>
                        <video-player  class="video-player vjs-custom-skin"
                                       ref="videoPlayer"
                                       :options="playerOptions"
                                       :playsinline="playsinline"
                        >
                        </video-player>
                    </el-col>
                </el-row>
                <span slot="footer" class="dialog-footer">
                <el-button @click="playVideoDialog = false">关 闭</el-button>
            </span>
            </el-dialog>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="videoSelectModal = false">关闭</el-button>
        </div>
        <el-dialog
            title="音频预览"
            :visible.sync="playAudioDialog"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px"
            >
            <el-row>
                <el-col>
                    <aplayer :audio="audioInfo" />
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="playAudioDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="图文预览"
            :visible.sync="viewDialog"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px"
        >
            <div>
                <el-row>
                    <el-col v-html="articleInfo.htmlContent">
                    </el-col>
                </el-row>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="viewDialog = false">取消</el-button>
                <el-button  type="primary" @click="viewDialog = false">确定</el-button>
            </div>
        </el-dialog>
    </el-dialog>
</template>

<script>
    import Pagination from '@/components/Pagination';
    // import GnvqTree from "@v/components/GnvqTree";
    import GnvqStoreTree from "@v/components/GnvqStoreTree";
    import GroupEditorSelect from "@v/components/GroupEditorSelect";
    export default {
        components: {
            Pagination,
            // GnvqTree,
            GnvqStoreTree,
            GroupEditorSelect,
            // SingleUploadImg,
            // MutiUploadImg,
            // AreaSelect
        },
        filters: {
            useStatusFilter(status) {
                const statusMap = {
                    0: '禁用',
                    1: '可用',
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    1: '已删除',
                    0: '正常',
                };
                return statusMap[status]
            },
            videoTypeFilter(status) {
                const statusMap = {
                    1: '基础课',
                    2: '专业课',
                };
                return statusMap[status]
            },
            videoTypeColorFilter(status) {
                const statusMap = {
                    1: '',
                    2: 'success'
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                activeName: 'video',
                type: 'video',
                portUrl: 'course/video/query',
                videoSelectModal: false,
                //列表数据
                videoTableData: [],
                audioTableData: [],
                articleTableData: [],
                videoSelectData: [],
                audioSelectData: [],
                articleSelectData: [],
                // tableLoading: false,
                videoTableLoading: false,
                audioTableLoading: false,
                articleTableLoading: false,
                //视频列表查询数据
                videoQueryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    useStatus: 1,
                    videoType: '',
                    baseCatId: '',
                    playSource: '',
                    transType: '',
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    groupId: '',
                    orgId: this.appCookies.getCookieOID()
                },
                //音频列表查询数据
                audioQueryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    uploadStatus: '',
                    useStatus: 1,
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    audioType: '',
                    baseCatId: '',
                    playSource: '',
                    transType: '',
                    groupId: '',
                    orgId: this.appCookies.getCookieOID()
                },
                //图文列表查询数据
                articleQueryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    deleteStatus: '0',
                    useStatus: 1,
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    articleType: '',
                    baseCatId: '',
                    groupId: '',
                    orgId: this.appCookies.getCookieOID()
                },
                //控制组件显示
                playVideoDialog: false,
                teacherDialog: false,
                uploadVideoDialog: false,
                editDrawer: false,
                //视频预览
                playerOptions: {
                    // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    aspectRatio: '16:9',
                    // width: document.documentElement.clientWidth, // 默认视频全屏时的最大宽度
                    autoplay: false, // 是否自动播放
                    muted: false, // 是否默认静音
                    language: 'zh-CN',
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    loop: false, // 导致视频一结束就重新开始。
                    playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4",
                        // mp4
                        src: "",
                        //src: "https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218114723HDu3hhxqIT.mp4"
                        // webm
                        // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
                    }], // 视频地址
                    poster: "", // 封面
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        fullscreenToggle: true  // 全屏按钮
                    }
                },
                //音频预览
                playAudioDialog: false,
                audioInfo: {
                    name: '',
                    artist: '',
                    // url: 'http://qasfiles.qdrxhz.cn/group1/M00/00/05/rBUABGJFiTKAC9_QADeqDvCR14s028.mp3',
                    url: '',
                    cover: '', // prettier-ignore
                },
                //图文预览
                viewDialog: false,
                articleInfo: {},
                selectType: 'ALL', // ALL / MUTI / SINGLE
                businessType: '',
                expandedKeys: []
            };
        },
        mounted () {
        },
        computed: {
            //视频预览相关
            player() {
                return this.$refs.videoPlayer.player
            },
            playsinline () {
                let ua = navigator.userAgent.toLocaleLowerCase()
                // x5内核
                if (ua.match(/tencenttraveler/) !== null || ua.match(/qqbrowse/) !== null) {
                    return false
                } else {
                    // ios端
                    return true
                }
            }
        },
        methods: {
            handleClick: function (event) {
                console.log(event);
                this.type = event.name;
                if (this.type === 'video') {
                    // this.portUrl = "course/video/query";
                    // this.currentQueryParams = this.videoQueryParams;
                    this.initVideoTable()
                } else if (this.type === 'audio') {
                    this.initAudioTable()
                } else if (this.type === 'article') {
                    this.initArticleTable()
                }
                console.log(this.portUrl);
            },
            open: function (selectType, businessType, gnvqCatId, gnvqId, levelId) {
                this.selectType = selectType;
                this.businessType = businessType;
                this.videoQueryParams.gnvqCatId = gnvqCatId;
                this.videoQueryParams.gnvqId = gnvqId;
                this.videoQueryParams.levelId = levelId;
                this.audioQueryParams.gnvqCatId = gnvqCatId;
                this.audioQueryParams.gnvqId = gnvqId;
                this.audioQueryParams.levelId = levelId;
                this.articleQueryParams.gnvqCatId = gnvqCatId;
                this.articleQueryParams.gnvqId = gnvqId;
                this.articleQueryParams.levelId = levelId;
                this.activeName = 'video';
                this.expandedKeys = []
                if (gnvqCatId) {
                    this.expandedKeys.push(gnvqCatId)
                }
                if (gnvqId) {
                    this.expandedKeys.push(gnvqId)
                }
                if (levelId) {
                    this.expandedKeys.push(levelId)
                }
                this.videoSelectModal = true;
                //console.log(this.queryParams.gnvqCatId, this.queryParams.gnvqId, this.queryParams.levelId);
                this.refreshVideoTable();
            },
            mutiSelect: function () {
                let dataList = [];
                if (this.activeName === 'video') {
                    if (this.videoSelectData.length === 0) {
                        this.$message({
                            message: '您未选择任何记录，请进行选择',
                            showClose: true, offset: 200, type: 'success'
                        });
                        return;
                    }
                    this.videoSelectData.forEach(item => {
                        dataList.push({
                            videoId: item.videoId,
                            chapterName: item.videoName,
                            relationType: 'VIDEO'
                        })
                    });
                } else if (this.activeName === 'audio') {
                    if (this.audioSelectData.length === 0) {
                        this.$message({
                            message: '您未选择任何记录，请进行选择',
                            showClose: true, offset: 200, type: 'success'
                        });
                        return;
                    }
                    this.audioSelectData.forEach(item => {
                        dataList.push({
                            videoId: item.audioId,
                            chapterName: item.audioName,
                            relationType: 'AUDIO'
                        })
                    });
                } else if (this.activeName === 'article'){
                    if (this.articleSelectData.length === 0) {
                        this.$message({
                            message: '您未选择任何记录，请进行选择',
                            showClose: true, offset: 200, type: 'success'
                        });
                        return;
                    }
                    this.articleSelectData.forEach(item => {
                        dataList.push({
                            videoId: item.articleId,
                            chapterName: item.articleName,
                            relationType: 'ARTICLE'
                        })
                    });
                }
                console.log(dataList);
                this.$emit('callback', dataList, this.selectType, this.businessType);
                this.videoSelectModal = false;
            },
            singleSelect: function (row) {
                let dataList = [];
                if (this.activeName === 'video') {
                    dataList.push({
                        videoId: row.videoId,
                        chapterName: row.videoName,
                        relationType: 'VIDEO'
                    })
                } else if (this.activeName === 'audio'){
                    dataList.push({
                        videoId: row.audioId,
                        chapterName: row.audioName,
                        relationType: 'AUDIO'
                    })
                } else if (this.activeName === 'article'){
                    dataList.push({
                        videoId: row.articleId,
                        chapterName: row.articleName,
                        relationType: 'ARTICLE'
                    })
                }
                this.$emit('callback', dataList, this.selectType, this.businessType);
                this.videoSelectModal = false;
            },
            handleSelectionChange1(val) {
                this.videoSelectData = val;
                console.log(this.videoSelectData);
            },
            handleSelectionChange2(val) {
                this.audioSelectData = val;
                console.log(this.audioSelectData);
            },
            handleSelectionChange3(val) {
                this.articleSelectData = val;
                console.log(this.articleSelectData);
            },
            //获取视频列表
            initVideoTable: function () {
                this.tableLoading = true;
                // let url = this.portUrl;
                this.apis.postForm('course/video/query', this.videoQueryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.videoTableData = res.data;
                            this.videoQueryParams.totalRecord = res.count;
                            console.log(res);
                            this.videoTableLoading = false;
                        } else {
                            this.videoTableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.videoTableLoading = false;
                    console.log(err)
                });
            },
            refreshVideoTable: function () {
                this.videoQueryParams.pageNumber = 0;
                this.initVideoTable();
            },
            //获取音频列表
            initAudioTable: function () {
                this.tableLoading = true;
                this.apis.postForm('course/audio/query', this.audioQueryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.audioTableData = res.data;
                            this.audioQueryParams.totalRecord = res.count;
                            console.log(res);
                            this.audioTableLoading = false;
                        } else {
                            this.audioTableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.audioTableLoading = false;
                    console.log(err)
                });
            },
            refreshAudioTable: function () {
                this.audioQueryParams.pageNumber = 0;
                this.initAudioTable();
            },
            //获取图文列表
            initArticleTable: function () {
                this.tableLoading = true;
                this.apis.postForm('course/article/query', this.articleQueryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.articleTableData = res.data;
                            this.articleQueryParams.totalRecord = res.count;
                            console.log(res);
                            this.articleTableLoading = false;
                        } else {
                            this.articleTableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.articleTableLoading = false;
                    console.log(err)
                });
            },
            refreshArticleTable: function () {
                this.articleQueryParams.pageNumber = 0;
                this.initArticleTable();
            },
            //gnvqTree组件（视频分类）callback方法
            toQuery: function (someType, data) {
                // if (this.type === 'video') {
                //     this.videoQueryParams.videoType = someType
                //     this.changeQueryParams(someType, data, this.videoQueryParams)
                //     this.initVideoTable()
                // } else if (this.type === 'audio') {
                //     this.audioQueryParams.audioType = someType
                //     this.changeQueryParams(someType, data, this.audioQueryParams)
                //     this.initAudioTable()
                // } else if (this.type === 'article') {
                //     this.articleQueryParams.articleType = someType
                //     this.changeQueryParams(someType, data, this.articleQueryParams)
                //     this.initArticleTable()
                // }
                this.videoQueryParams.videoType = someType
                this.changeQueryParams(someType, data, this.videoQueryParams)
                this.initVideoTable()
                this.audioQueryParams.audioType = someType
                this.changeQueryParams(someType, data, this.audioQueryParams)
                this.initAudioTable()
                this.articleQueryParams.articleType = someType
                this.changeQueryParams(someType, data, this.articleQueryParams)
                this.initArticleTable()
            },
            changeQueryParams: function (someType, data, someQueryParams) {
                someQueryParams.gnvqCatId = '';
                someQueryParams.gnvqId = '';
                someQueryParams.levelId = '';
                someQueryParams.articleType = '';
                someQueryParams.baseCatId = '';
                someQueryParams.groupId = '';
                if (someType === 1) {
                    someQueryParams.baseCatId = data.dictId;
                } else {
                    if (data.type === 'CAT') {
                        someQueryParams.gnvqCatId = data.id;
                    } else if (data.type === 'INFO') {
                        someQueryParams.gnvqCatId = data.categoryId;
                        someQueryParams.gnvqId = data.id;
                    } else {
                        someQueryParams.gnvqCatId = data.categoryId;
                        someQueryParams.gnvqId = data.parentId;
                        someQueryParams.levelId = data.id;
                    }
                }
            },
            //视频预览
            toPlayAliyun: function (row) {
                this.apis.get('course/video/getById?videoId=' + row.videoId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.showVideoAli(res.data.aliyunUrl);
                            this.videoId = res.data.videoId;
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            showVideoAli: function (videoPathUrl) {
                //this.playerOptions['sources'][0].src = "https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218114723HDu3hhxqIT.mp4"
                //this.playerOptions['sources'][0].src = this.info.videoPathUrl;
                this.playerOptions['sources'][0].src = videoPathUrl;
                this.playVideoDialog = true;
            },
            //音频预览
            toPlayAudio: function (row) {
                // this.uploadInfo.audioId = row.audioId;
                // this.uploadInfo.audioName = row.audioName;
                this.apis.get('course/audio/getById?audioId=' + row.audioId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.showAPlayer(res.data.aliyunUrl, res.data.audioName, res.data.audioImgUrl);
                            // this.audioId = res.data.audioId;
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            showAPlayer: function (audioPathUrl, audioName, audioImgUrl) {
                this.audioInfo.url = audioPathUrl;
                this.audioInfo.name = audioName;
                this.audioInfo.cover = audioImgUrl;
                console.log(this.audioInfo.cover);
                this.playAudioDialog = true;
            },
            //图文预览
            toReview: function (keyId) {
                this.apis.get('course/article/getById?articleId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.articleInfo = res.data;
                            this.viewDialog = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
