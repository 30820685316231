<template>
    <el-drawer
        title="内容编排"
        :visible.sync="courseChapterEditor"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        size="80%"
        >
        <div v-loading="mainLoading"
         :element-loading-text="mainLoadingText" class="dtlmain" ref="containDiv">
        <el-row :gutter="16" type="flex" class="row-container">
            <el-col>
                <el-form :inline="true" ref="formInline" :model="queryParams">
                    <el-form-item label="">
                        <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="queryParams.deleteStatus" placeholder="请选择">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="正常" value="0"></el-option>
                            <el-option label="已删除" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search"  @click="getChapterList">查询</el-button>
                    </el-form-item>
                    <el-button-group style="float:right">
                        <el-button type="success" round icon="el-icon-delete" @click="toDeleteRows">删除</el-button>
                        <el-button type="success" round icon="el-icon-plus" @click="toAddRow">插入行</el-button>
                        <el-button type="success" round icon="el-icon-plus" @click="MtpVideoSelect">批量选择</el-button>
                        <el-button type="success" round icon="el-icon-plus" @click="toCopyCource">复制课程</el-button>
                        <el-button type="primary" round icon="el-icon-download" @click="toDownloadQr">下载二维码</el-button>
                        <el-button type="primary" round icon="el-icon-circle-check" @click="saveCourceData">保存</el-button>
                    </el-button-group>
                </el-form>
            </el-col>
        </el-row>
        <el-row :gutter="16" style="height: calc(100% - 100px)" class="row-container">
            <el-col :span="24" style="height: 80%">
                <!-- @cell-mouse-enter.once='rowDrop' -->
                <el-table
                    v-loading="tableLoading"
                    :data="tableData"
                    fit
                    highlight-current-row
                    row-key="rowKeyId"
                    style="width: 100%;"
                    :default-expand-all="true"
                    :tree-props="treeProps"
                    ref="chaperTable"
                    :indent="24"
                    :height="height"
                    @selection-change="handleSelectionChange"
                >   <el-table-column
                        type="selection"
                        width="50">
                    </el-table-column>
                    <el-table-column label="章/节/知识点" min-width="250px" align="left">
                        <template slot-scope="{row}">
                            <i v-if="row.chapterType===1" class="el-icon-folder" style="color: #1890FF; font-size: 16px; margin: 0 3px"></i>
                            <i v-else-if="row.chapterType===2" class="el-icon-folder"  style="color: #1890FF; font-size: 16px; margin: 0 3px"></i>
                            <i v-else class="el-icon-tickets"  style="color: #1890FF;font-size: 16px; margin: 0 3px"></i>
                            <el-input v-model="row.chapterName" placeholder="请输入章节章/节/知识点"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="二维码" width="100px" align="center">
                        <template slot-scope="{ row }">
                            <el-popover
                                v-show="row.chapterType===3"
                                placement="right"
                                width="170"
                                trigger="click">
                                <img slot="reference" src="../../../assets/images/qrcode.png" style="width:20px;height:20px;text-align:center;" @click="getChapterQr(row.courseId, row.chapterId)"  alt="" title="点击扫码，手机观看">
                                <div class="qr">
                                    <img :src="qrImageUrl">
                                    <p style="font-size: 13px; color: #666666">微信扫码，手机观看课程</p>
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="类型" width="120px" align="center">
                        <template slot-scope="{row}">
                            <el-select v-model="row.chapterType" placeholder="请选择类型">
                                <el-option label="章" :value="1"></el-option>
                                <el-option label="节" :value="2"></el-option>
                                <el-option label="知识点" :value="3"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否免费" width="80px" align="center">
                        <template slot-scope="{row}">
                            <el-checkbox :true-label="1" :false-label="0" v-model="row.canFree" v-show="row.chapterType===3"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column label="排序号" width="100px" align="center">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.orderNum" placeholder="请输入排序号"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="资源" width="100px" align="left">
                        <template slot-scope="{ row }">
                            <el-tooltip effect="light" content="选择资源" placement="top" style="padding-right:5px">
                                <el-button v-if="row.chapterType === 3" type="text" icon="el-icon-search" @click="toAddVideo(row)"></el-button>
                            </el-tooltip>
                            <el-tooltip effect="light" content="资源内容预览" placement="top-start">
                                <el-button v-if="row.chapterType === 3 && row.videoId !== null && row.videoId !== '' && row.relationType === 'VIDEO'"
                                    type="text"
                                    icon="el-icon-video-camera"
                                    @click="toPreview(row.relationType, row.videoId)">
                                </el-button>
                                <el-button v-else-if="row.chapterType === 3 && row.videoId !== null && row.videoId !== '' && row.relationType === 'AUDIO'"
                                    type="text"
                                    icon="el-icon-service"
                                    @click="toPreview(row.relationType, row.videoId)">
                                </el-button>
                                <el-button v-else-if="row.chapterType === 3 && row.videoId !== null && row.videoId !== '' && row.relationType === 'ARTICLE'"
                                    type="text"
                                    icon="el-icon-reading"
                                    @click="toPreview(row.relationType, row.videoId)">
                                </el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="300px" fixed="right" class-name="small-padding fixed-width">
                        <template slot-scope="{row}">
                            <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDeleteSingleRow(tableData,row.rowKeyId)">删除</el-button>
                            <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-edit" @click="toEdit(row)">修改</el-button>
                            <el-button v-if="row.deleteStatus===0" type="text" size="mini" @click="toAddBrother(row)">添加平级</el-button>
                            <el-button v-if="row.chapterType !== 3 && row.deleteStatus === 0" type="text" size="mini" @click="toAddChild(row)">添加下级</el-button>
                            <el-button v-if="row.chapterType !== 3 && row.deleteStatus === 0" type="text" size="mini" @click="toAddChildVideo(row)">批量选择</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <!-- 修改课程信息对话框 -->
        <el-dialog
            title="编辑"
            :visible.sync="editModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="700px">
                    <el-form ref="chapterForm" :model="info" label-suffix=":" :rules="ruleValidate" label-width="150px">
                        <el-row>
                            <el-col :span="20">
                                <el-form-item v-show="info.chapterType===3" label="免费观看" prop="canFree">
                                    <el-radio-group v-model="info.canFree">
                                        <el-radio :label="0">否</el-radio>
                                        <el-radio :label="1">是</el-radio>
                                    </el-radio-group>
                                </el-form-item>
<!--                                <el-form-item v-show="info.chapterType===3" label="视频" prop="videoId">-->
<!--                                    <el-input v-model="info.videoName">-->
<!--                                    </el-input>-->
<!--                                </el-form-item>-->
                                <el-form-item label="章/节/知识点名称" prop="chapterName">
                                    <el-input v-model="info.chapterName"></el-input>
                                </el-form-item>
                                <el-form-item label="所属上级" prop="rowKeyParentId">
                                    <SelectTree :tree-data="parentChapterList" :selectId.sync="info.rowKeyParentId"
                                                :treeProps="selectTreeProps" :nodeKey="'rowKeyId'"
                                                :onlySelectLeaf="false"></SelectTree>
                                </el-form-item>
                                <el-form-item label="序号" prop="orderNum">
                                    <span slot="label">
                                        序号：
                                        <el-tooltip  content="显示的顺序，不控制可输入0" effect="light" placement="top">
                                            <i class="el-icon-question" style="color:red"></i>
                                        </el-tooltip>
                                    </span>
                                        <el-input v-model="info.orderNum"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
            <span slot='footer'>
                <el-button type="text"  @click="editModal=false">取消</el-button>
                <el-button type="primary" @click="saveUpdChapter('chapterForm')">保存</el-button>
            </span>
        </el-dialog>
        <!-- 视频列表对话框 -->
        <VideoSelectV2 ref="videoSelect" @callback="videoSelectCallback"></VideoSelectV2>
        <el-dialog title="课后练习"
            :visible.sync="videoExamModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="800px">
            <!-- <VideoExamItem :chapterId="currentChapterId"></VideoExamItem> -->
        </el-dialog>
        <!-- 复制课程dialog -->
        <el-dialog
            title="复制课程内容"
            :visible.sync="copyModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="800px">
            <el-row :gutter="32" class="row-container" style="margin: 0 10px">
                <el-col :span="24">
                    <el-form label-width="100px">
                        <el-form label="课程" label-width="140px" label-suffix=":">
                            <el-select
                                v-model="copyCourseId"
                                filterable
                                remote
                                :remote-method="getCourseList"
                                @change="getCourseChapterTree"
                                placeholder="请输入课程名称进行检索">
                                <el-option
                                    v-for="item in courseList"
                                    :key="item.courseId"
                                    :label="item.courseName"
                                    :value="item.courseId">
                                </el-option>
                            </el-select>
                        </el-form>
                    </el-form>
                </el-col>
                <el-col :span="24">
                    <el-divider></el-divider>
                </el-col>
                <el-col :span="24">
                    <el-alert type="warning" show-icon>复制包含章、节、知识点以及课后练习</el-alert>
                </el-col>
                <el-col :span="20" :offset="2">
                    <el-tree
                        ref="chapterTree"
                        node-key="chapterId"
                        :data="courseChapterData"
                        :props="{label: 'chapterName', children: 'children'}"
                        :check-on-click-node="true"
                        :expand-on-click-node="false"
                        :default-checked-keys="checkedKeys"
                        show-checkbox
                        highlight-current
                    ></el-tree>
                </el-col>
            </el-row>
            <div slot='footer'>
                <el-button type="text" @click='copyModal=false'>取消</el-button>
                <el-button type="primary" @click="copySelectedChapter">确定</el-button>
            </div>
        </el-dialog>
        <!-- 视频预览dialog -->
        <el-dialog
            title="视频预览"
            :visible.sync="videoModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="700px">
            <el-row>
                <el-col>
                    <video-player  class="video-player vjs-custom-skin"
                                   ref="videoPlayer"
                                   :options="playerOptions"
                                   :playsinline="playsinline"
                    >
                    </video-player>
                </el-col>
            </el-row>
            <div slot='footer'>
                <el-button type="text"  @click="close">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="音频预览"
            :visible.sync="playAudioDialog"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px"
            >
            <el-row>
                <el-col>
                    <aplayer :audio="audioInfo" />
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="playAudioDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="图文预览"
            :visible.sync="viewDialog"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px"
        >
            <div>
                <el-row>
                    <el-col v-html="articleInfo.htmlContent">
                    </el-col>
                </el-row>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="viewDialog = false">取消</el-button>
                <el-button  type="primary" @click="viewDialog = false">确定</el-button>
            </div>
        </el-dialog>
        </div>
            <div class="demo-drawer__footer">
            <el-button @click="courseChapterEditor = false">取消</el-button>
            <el-button  type="primary" @click="drawerSaveCourceData">保存</el-button>
        </div>
    </el-drawer>
</template>

<script>
import Sortable from 'sortablejs';
import SelectTree from '@/components/tree/SelectTree';
import VideoSelectV2 from './VideoSelectV2';
//import VideoExamItem from './VideoExamItem'
export default {
    components: {
        SelectTree,
        VideoSelectV2,
        //VideoExamItem
    },
    props: ['id'],
    filters: {
        chapterTypeFilter(status) {
            const statusMap = {
                1: '章',
                2: '节',
                3: '知识点',
            };
            return statusMap[status]
        },
        chapterTypeColorFilter(status) {
            const statusMap = {
                1: 'success',
                2: '',
                3: 'warning',
            };
            return statusMap[status]
        },
        canFreeFilter(status) {
            const statusMap = {
                0: '否',
                1: '是',
            };
            return statusMap[status]
        },
        deleteStatusFilter(status) {
            const statusMap = {
                0: '正常',
                1: '已删除',
            };
            return statusMap[status]
        },
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'danger',
            };
            return statusMap[status]
        },
        colorFilter2(status) {
            const statusMap = {
                0: 'danger',
                1: 'success',
            };
            return statusMap[status]
        },
    },
    data() {
        const validateNumber = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('输入序号'));
            } else if (!Number.isInteger(+value)) {
                callback(new Error('请输入数值'));
            } else {
                callback();
            }
        };
        return {
            courseChapterEditor: false,
            mainLoading: false,
            mainLoadingText: '正在导出数据，请稍等',
            courseId: '',
            copyCourseId: '',
            checkedKeys: [],
            queryParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                keyword: '',
                deleteStatus: '0',
                isAll: 1,
                rowCount: 1000
            },
            sortable: null,
            tableData: [],
            selectdata: [],
            currentRow: {},
            info: {
                rowKeyId: 0,
                rowKeyParentId: 0,
                orderNum: 0
            },
            oldRowInfo: {},
            tableLoading: false,
            editModal: false,
            copyModal: false,
            ruleValidate: {
                chapterName: [
                    { required: true, message: '请输入章/节/知识点名称', trigger: 'blur' }
                ],
                chapterType: [
                    { required: true, type: 'number', message: '请选择类型', trigger: 'blur' }
                ],
                orderNum: [
                    // { required: true, message: '请输入序号', trigger: 'blur' },
                    { validator: validateNumber, trigger: 'blur' }
                    // { type: 'number', message: '请输入数字', trigger: 'blur' }
                ],
            },
            parentChapterList: [],
            selectTreeProps: {
                value: 'rowKeyId',
                label: 'rowKeyName',
                children: 'children'
            },
            height: 100,
            videoModal: false,
            playerOptions: {
                // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                aspectRatio: '16:9',
                // width: document.documentElement.clientWidth, // 默认视频全屏时的最大宽度
                autoplay: false, // 是否自动播放
                muted: false, // 是否默认静音
                language: 'zh-CN',
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                loop: false, // 导致视频一结束就重新开始。
                playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4",
                    // mp4
                    src: "",
                    // webm
                    // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
                }], // 视频地址
                poster: "", // 封面
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true  // 全屏按钮
                }
            },
            playAudioDialog: false,
            audioInfo: {
                name: '',
                artist: '',
                // url: 'http://qasfiles.qdrxhz.cn/group1/M00/00/05/rBUABGJFiTKAC9_QADeqDvCR14s028.mp3',
                url: '',
                cover: '', // prettier-ignore
            },
            viewDialog: false,
            articleInfo: {},
            videoExamModal: false,
            currentChapterId: '',
            courseList: [],
            courseChapterData: [],
            qrImageUrl: '',
            treeProps: {
                children: 'children',
                hasChildren: 'hasChildren'
            },
            flattArray: [],
            gnvqCatId: '',
            gnvqId: '',
            levelId: '',
        }
    },
    computed: {
        player() {
            return this.$refs.videoPlayer.player
        },
        playsinline () {
            let ua = navigator.userAgent.toLocaleLowerCase()
            // x5内核
            if (ua.match(/tencenttraveler/) !== null || ua.match(/qqbrowse/) !== null) {
                return false
            } else {
                // ios端
                return true
            }
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.getHeight();
        });
    },
    methods: {
        /**
         * 初始化数据
         */
        open: function (courseId, gnvqCatId, gnvqId, levelId) {
            this.gnvqCatId = gnvqCatId;
            this.gnvqId = gnvqId;
            this.levelId = levelId;
            this.courseId = courseId;
            this.courseChapterEditor = true;
            this.getChapterList();
            // this.$nextTick(function() {
            //     this.$refs.courseDetailEditorV3.init(courseId);
            // });
        },
        getChapterList: function () {
            this.mainLoading = true;
            this.mainLoadingText = '正在查询数据，请稍等';
            let data = {
                courseId: this.courseId,
                deleteStatus: 0,
                chapterType: ''
            };
            this.apis.postForm('course/chapter/getList', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        if (this.tableData===null) {
                            this.tableData = [];
                        } else {
                            this.initRowKeyId(this.tableData, 0);
                        }
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                    this.mainLoading = false;
                }).catch((err) => {
                this.mainLoading = false;
                console.log(err)
            });
        },
        initRowKeyId: function (childrenList, rowKeyParentId) {
            let that = this;
            childrenList.forEach(item => {
                item.rowKeyParentId = rowKeyParentId
                if (item.children && item.children.length > 0) {
                    that.initRowKeyId(item.children, item.rowKeyId);
                }
            })
        },
        toEdit: function (currentRow) {
            this.info = currentRow;
            this.oldRowInfo = JSON.parse(JSON.stringify(currentRow));
            // console.log(this.info)
            this.parentChapterList = [
                // { rowKeyId: 0, rowKeyName: "请选择选择" }
            ];
            this.getSelectTreeList(this.tableData, this.parentChapterList);
            this.editModal = true;
        },
        getSelectTreeList: function(childrenList, parentChapterList) {
            let that = this;
            childrenList.forEach(item => {
                if (item.chapterType !== 3) {
                    let nodeData = {
                        rowKeyId: item.rowKeyId,
                        rowKeyName: item.chapterName
                    }
                    parentChapterList.push(nodeData);
                    if (item.children && item.children.length >0) {
                        nodeData.children = [];
                        that.getSelectTreeList(item.children, nodeData.children);
                    }
                }
            })
        },
        findParentRowKeyId(list, rowKeyId) {
            let that = this;
            let result;
            if (list) {
                for (let i = 0; i< list.length; i++) {
                    let row = list[i];
                    if (row.rowKeyId === rowKeyId) {
                        return list;
                    } else if (row.children && row.children.length){
                        result = that.findParentRowKeyId(row.children, rowKeyId);
                        if (result) {
                            return result;
                        }
                    }
                }
            }
            return result;
        },


        handleSelectionChange(val) {
            this.selectdata = val;
            console.log(this.selectdata);
        },
        //插入行
        toAddRow: function () {
            // console.log(this.tableData);
            if (this.tableData === null) {
                this.tableData = [];
            }
            this.queryParams.rowCount = this.queryParams.rowCount + 1;
            let newRow= {
                rowKeyId: this.queryParams.rowCount,
                rowKeyParentId: 0,
                chapterId: '',
                chapterName: '',
                chapterType: 1,
                canFree: 0,
                orderNum: this.tableData.length,
                videoId: '',
                deleteStatus: 0,
                children: []
            };
            this.tableData.push(newRow);
            // let examItemIds = [];
            // this.selectdata.forEach(function (item) {
            //     examItemIds.push(item.examItemId)
            // });
        },
        //删除选中行
        toDeleteRows: function () {
            console.log(this.tableData);
            if (this.selectdata.length === 0) {
                this.$message({
                    message: '您未选择任何记录，请进行选择',
                    showClose: true, offset: 200, type: 'success'
                });
                return;
            }
            this.selectdata.forEach(element => {
                this.toDeleteSingleRow(this.tableData, element.rowKeyId)
                // for (let i = 0; i < this.tableData.length; i++){
                //     if (this.tableData[i].chapterId === element.chapterId){
                //         this.tableData.splice(i, 1);
                //         break
                //     }
                // }
            });
            // console.log(this.tableData);
        },
        //批量保存
        saveCourceData: function(){
            console.log(this.tableData);
            let url = "course/chapter/batchSave";
            let data = {
                courseId: this.courseId,
                createBy: this.appCookies.getCookieUID(),
                createByname: this.appCookies.getCookieUNAME(),
                modifyBy: this.appCookies.getCookieUID(),
                modifyByname: this.appCookies.getCookieUNAME(),
                chapterList: this.tableData
            }
            this.apis.postJson(url, data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '保存成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.getChapterList();
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        //drawer确认保存
        drawerSaveCourceData: function (params) {
            this.saveCourceData();
            this.courseChapterEditor = false;
        },
        //复制课程
        toCopyCource: function () {
            this.courseChapterData = []
            this.copyCourseId = ''
            this.copyModal = true;
        },
        getCourseList: function (val) {
            this.apis.get('course/info/getList?keyword=' + val + '&orgId=' + this.appCookies.getCookieOID())
                .then((res) => {
                    if (res.code === 1) {
                        this.courseList = res.data;
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getCourseChapterTree: function (id) {
            // console.log(id)
            let data = {
                courseId: id,
                deleteStatus: 0,
                chapterType: '',
            };
            this.apis.postForm('course/chapter/getList', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.courseChapterData = res.data;
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        saveUpdChapter: function (refFrom) {
            if (this.oldRowInfo.rowKeyParentId !== this.info.rowKeyParentId) {
                this.toDeleteSingleRow(this.tableData, this.info.rowKeyId); // 删除当前节点
                let parentChapter = this.findByRowKeyId(this.tableData, this.info.rowKeyParentId); // 查找所属上级
                if (parentChapter) {
                    if (!parentChapter.children) {
                        parentChapter.children = []
                    }
                    parentChapter.children.push(this.info)
                }
            }
            this.editModal = false;
        },
        //确定（复制课程）
        copySelectedChapter: function () {
            let selectChapters = this.$refs.chapterTree.getCheckedNodes(false, true);
            // console.log(selectChapters);
            let chapterIds = []
            selectChapters.forEach((item) => {
                chapterIds.push(item.chapterId)
            })
            // console.log(chapterIds);
            let copyChapters = []
            this.getCopyObj(this.courseChapterData, chapterIds, copyChapters, 0)

            this.tableData = this.tableData.concat(copyChapters);
            this.copyModal = false;
        },
        getCopyObj: function (chapterList, chapterIds, copyChapters, rowKeyParerntId){
            for (let i = 0; i < chapterList.length; i++) {
                let chapter = chapterList[i];
                if (chapterIds.includes(chapter.chapterId)) {
                    console.log('includes');
                    this.queryParams.rowCount = this.queryParams.rowCount + 1;
                    let newChapter = {
                        rowKeyId: this.queryParams.rowCount,
                        rowKeyParerntId: rowKeyParerntId,
                        chapterName: chapter.chapterName,
                        chapterType: chapter.chapterType,
                        canFree: chapter.canFree,
                        orderNum: this.tableData.length,
                        videoId: chapter.videoId,
                        deleteStatus: 0,
                        children: [],
                    }
                    copyChapters.push(newChapter);
                    if (chapter.children && chapter.children.length > 0) {
                        this.getCopyObj(chapter.children, chapterIds, newChapter.children, newChapter.rowKeyId);
                    }
                }
            }
        },
        getCopyObj_bak: function (selectChapters, copyChapters, rowKeyParerntId){
            for (let i = 0; i < selectChapters.length; i++) {
                let selectChildX = selectChapters[i];
                this.queryParams.rowCount = this.queryParams.rowCount + 1;
                let newChapter = {
                    rowKeyId: this.queryParams.rowCount,
                    rowKeyParerntId: rowKeyParerntId,
                    chapterName: selectChildX.chapterName,
                    chapterType: selectChildX.chapterType,
                    canFree: selectChildX.canFree,
                    orderNum: this.tableData.length,
                    videoId: selectChildX.videoId,
                    deleteStatus: 0,
                    children: [],
                }
                copyChapters.push(newChapter);
                if (selectChildX.children && selectChildX.children.length > 0) {
                    this.getCopyObj_bak(selectChildX.children, newChapter.children, newChapter.rowKeyId);
                }
            }
        },
        //列表上方【插入视频】
        MtpVideoSelect: function () {
            // selectType： 打开选择窗口后，视频是否可以多选单选，取值: ALL MUTI  SINGLE
            // businessType： 区分不同的业务操作，回调时可以判断数据处理方式，取值: TOPADD ROWADD  REFVIDEO
            this.$refs.videoSelect.open("ALL", "TOPADD", this.gnvqCatId, this.gnvqId, this.levelId)
        },
        //列表操作>插入视频
        toAddChildVideo: function (row) {
            this.$refs.videoSelect.open("ALL", "ROWADD", this.gnvqCatId, this.gnvqId, this.levelId)
            this.currentRow = row;
        },
        //列表操作>关联视频
        toAddVideo: function (row) {
            this.$refs.videoSelect.open("SINGLE", "REFVIDEO", this.gnvqCatId, this.gnvqId, this.levelId)
            this.currentRow = row;
        },
        compareVideoId: function (arr, currentId, relationType) {
            let exist = false;
            let that = this;
            for (let i = 0; i < arr.length; i++) {
                let el = arr[i];
                if (el.videoId === currentId && el.relationType === relationType) {
                    return true;
                } else {
                    if (el.children && el.children.length) {
                        exist = that.compareVideoId(el.children, currentId, relationType)
                        if (exist) {
                            return exist;
                        }
                    }
                }
            }
            return exist;
        },
        //视频选择组件videoSelectV2的回调
        // dataList = {
        //      videoId: '',
        //      chapterName: '',
        //      relationType: ''
        //  }
        videoSelectCallback: function (dataList, selectType, businessType) {
            //this.getDealData();
            // businessType: TOPADD ROWADD  REFVIDEO
            console.log(dataList);
            if (businessType==='TOPADD') {
                dataList.forEach(item => {
                    let hasExist = false;
                    hasExist = this.compareVideoId(this.tableData, item.videoId, item.relationType);
                    if (!hasExist) {
                        this.queryParams.rowCount = this.queryParams.rowCount + 1;
                        let newRow = {
                            rowKeyId: this.queryParams.rowCount,
                            rowKeyParentId: 0,
                            chapterId: '',
                            chapterName: item.chapterName,
                            chapterType: 3,
                            canFree: 0,
                            orderNum: this.tableData.length,
                            videoId: item.videoId,
                            deleteStatus: 0,
                            relationType: item.relationType,
                            children: [],
                        };
                        // newRow.videoId = item.videoId;
                        this.tableData.push(newRow);
                    }
                });
            } else if (businessType==='ROWADD') {
                dataList.forEach(item => {
                    let hasExist = false;
                    hasExist = this.compareVideoId(this.tableData, item.videoId, item.relationType);
                    // this.flattArray.forEach(chapter => {
                    //     if (chapter.videoId === item.videoId) {
                    //         hasExist = true;
                    //     }
                    // })
                    if (!hasExist) {
                        this.queryParams.rowCount = this.queryParams.rowCount + 1;
                        let newRow = {
                            rowKeyId: this.queryParams.rowCount,
                            rowKeyParentId: this.currentRow.rowKeyId,
                            chapterId: '',
                            chapterName: item.chapterName,
                            chapterType: 3,
                            canFree: 0,
                            orderNum: this.currentRow.orderNum,
                            videoId: item.videoId,
                            relationType: item.relationType,
                            deleteStatus: 0,
                            children: [],
                        };
                        this.currentRow.children.push(newRow);
                    }
                });
            } else if (businessType==='REFVIDEO') {
                let videoInfo = dataList[0];
                this.currentRow.videoId = videoInfo.videoId;
                this.currentRow.relationType = videoInfo.relationType;
                // eslint-disable-next-line no-undefined
                if (this.currentRow.chapterName === undefined || this.currentRow.chapterName === 'undefined' || this.currentRow.chapterName === '') {
                    this.currentRow.chapterName = videoInfo.chapterName;
                }
            }
            console.log(this.tableData);
        },
        //列表操作>删除当前行
        toDeleteSingleRow: function (dataList, currentId) {
            for (let i = 0; i < dataList.length; i++) {
                let el = dataList[i]
                if (el.rowKeyId === currentId) {
                    dataList.splice(i, 1)
                    break
                } else {
                    if (el.children && el.children.length) {
                        this.toDeleteSingleRow(el.children, currentId)
                    }
                }
            }
        },
        //列表操作>添加平级
        toAddBrother: function (row) {
            console.log(row);
            this.queryParams.rowCount = this.queryParams.rowCount + 1;
            console.log(this.queryParams.rowCount);
            let newRow = {
                rowKeyId: this.queryParams.rowCount,
                rowKeyParerntId: row.rowKeyId,
                chapterId: '',
                chapterName: '',
                chapterType: row.chapterType,
                canFree: 0,
                orderNum: row.orderNum,
                videoId: '',
                deleteStatus: 0,
                children: [],
            };

            // 根据row.rowKeyId，查找当前row所在的数组（父级的children）
            let parentChildList = [];
            for (let i = 0; i< this.tableData.length; i++) {
                let item = this.tableData[i];
                if (item.rowKeyId === row.rowKeyId) {
                    parentChildList = this.tableData;
                    break;
                } else {
                    parentChildList = this.findParentChilds(item.children, row.rowKeyId);
                    if (parentChildList) {
                        break;
                    }
                }
            }
            if (parentChildList) {
                // 添加到当前行的后面
                for (let i = 0; i < parentChildList.length; i++) {
                    let item = parentChildList[i]
                    if (item.rowKeyId === row.rowKeyId) {
                        parentChildList.splice(i + 1, 0, newRow)
                        break
                    }
                }
            }
        },
        /**
         * 查找当前row所在的数组（父级的children）
         */
        findParentChilds(list, rowKeyId) {
            let that = this;
            let result;
            if (list) {
                for (let i = 0; i< list.length; i++) {
                    let row = list[i];
                    if (row.rowKeyId === rowKeyId) {
                        return list;
                    } else {
                        result = that.findParentChilds(row.children, rowKeyId);
                        if (result) {
                            return result;
                        }
                    }
                }
            }
            return result;
        },
        /**
         * 根据rowKeyId 查找节点
         */
        findByRowKeyId(list, rowKeyId) {
            let that = this;
            let result;
            if (list) {
                for (let i = 0; i< list.length; i++) {
                    let row = list[i];
                    if (row.rowKeyId === rowKeyId) {
                        return row;
                    } else {
                        if (row.children) {
                            result = that.findByRowKeyId(row.children, rowKeyId);
                            if (result) {
                                return result;
                            }
                        }
                    }
                }
            }
            return result;
        },
        //列表操作>添加下级
        toAddChild: function (row) {
            // this.currentRow = row;
            this.queryParams.rowCount = this.queryParams.rowCount + 1;
            let newRow = {
                rowKeyId: this.queryParams.rowCount,
                rowKeyParentId: row.rowKeyId,
                chapterId: '',
                chapterName: '',
                chapterType: '',
                canFree: 0,
                orderNum: row.orderNum,
                videoId: '',
                deleteStatus: 0,
                children: [],
            };

            if (row.chapterType===1){
                newRow.chapterType = 2;
            } else if (row.chapterType===2){
                newRow.chapterType = 3;
            } else if (row.chapterType===3){
                return;
            }
            row.children.push(newRow);
        },

        getChapterQr: function (courseId, chapterId) {
            this.qrImageUrl = process.env.VUE_APP_API_HOST + 'notify/verify/getQr?bizType=CHAPTER&bizId=' + courseId + '&chapterId=' + chapterId + "&tenantId=" + this.appCookies.getTenantId();
        },
        getHeight () {
            let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight; // 浏览器高度
            // this.$refs.containDiv.offsetHeight
            // this.$refs.containDiv.offsetTop
            this.height = h - 255;
        },
        showVideo: function (videoPathUrl) {
            // this.playerOptions['sources'][0].src = this.info.videoPathUrl;
            this.playerOptions['sources'][0].src = videoPathUrl;
            this.videoModal = true;
        },
        toPlayAliyun: function (videoId) {
            this.apis.get('course/video/getById?videoId=' + videoId)
                .then((res) => {
                    if (res.code === 1) {
                        this.playerOptions['sources'][0].src = res.data.aliyunUrl;
                        this.videoModal = true;
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toPreview: function (relationType, videoId) {
            if (relationType === 'VIDEO') {
                this.apis.get('course/video/getById?videoId=' + videoId)
                .then((res) => {
                    if (res.code === 1) {
                        if (res.data.playSource===3) {
                            this.playerOptions['sources'][0].src = res.data.videoPath;
                        } else {
                            this.playerOptions['sources'][0].src = res.data.aliyunUrl;
                        }
                        this.videoModal = true;
                    }
                }).catch(err => {
                    console.log(err);
                });
            } else if (relationType === 'AUDIO') {
                this.apis.get('course/audio/getById?audioId=' + videoId)
                    .then((res) => {
                        if (res.code === 1) {
                            if (res.data.playSource===3) {
                                this.showAPlayer(res.data.audioPath, res.data.audioName, res.data.audioImgUrl);
                            } else {
                                this.showAPlayer(res.data.aliyunUrl, res.data.audioName, res.data.audioImgUrl);
                            }
                        }
                    }).catch(err => {
                    console.log(err);
                });
            } else if (relationType === 'ARTICLE') {
                this.apis.get('course/article/getById?articleId=' + videoId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.articleInfo = res.data;
                            this.viewDialog = true;
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }
        },
        showAPlayer: function (audioPathUrl, audioName, audioImgUrl) {
                this.audioInfo.url = audioPathUrl;
                this.audioInfo.name = audioName;
                this.audioInfo.cover = audioImgUrl;
                console.log(this.audioInfo.cover);
                this.playAudioDialog = true;
            },
        close: function () {
            this.$refs.videoPlayer.player.pause();
            this.videoModal=false;
        },
        toDownloadQr: function () {
            this.mainLoading = true;
            this.mainLoadingText = '正在导出数据，请稍等';
            let data = {
                courseId: this.courseId
            };
            this.apis.axios()({
                method: 'post',
                url: 'course/chapter/downloadQr',
                data: this.$http.adornParams(data),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                if (res.type === 'application/json') {
                    this.$message({
                        message: '未查询到数据',
                        showClose: true, offset: 200, type: 'error'
                    });
                    this.mainLoading = false;
                    return false
                }
                let date = new Date();
                let year = date.getFullYear();
                let timestamp = date.valueOf();
                this.downFile(res, year.toString() + timestamp.toString() + '.zip');
                this.mainLoading = false;
            }).catch(err => {
                console.log(err);
                this.mainLoading = false;
            });
        },
        /**
         * 文件流下载
         */
        downFile(content, fileName) {
            let blob = new Blob([content], {
                type: 'application/vnd.ms-excel'
            });
            let objectUrl = URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.style.display = 'none';
            link.href = objectUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        },
        //将树型数据变成平级数据
        getDealData() {
            const result = []
            const children = this.treeProps.children
            const rowKey = "rowKeyId";
            const func = function(arr, parent) {
                arr.forEach(item => {
                    const obj = Object.assign(item)
                    if (parent) {
                        if (obj.parentIds) {
                            obj.parentIds.push(parent[rowKey])
                        } else {
                            obj.parentIds = [parent[rowKey]]
                        }
                    }
                    // 将每一级的数据都一一装入result，不需要删除下面的children，方便拖动的时候根据下标直接拿到整个数据，包括当前节点的子节点
                    result.push(obj)
                    if (item[children] && item[children].length !== 0) {
                        func(item[children], item)
                    }
                })
            }
            func(this.tableData)
            this.flattArray = result
        },
        //拖拽功能
        rowDrop: function () {
            let el = this.$refs.chaperTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
            const self = this;
            // let el = document.querySelector('.el-table__body tbody');
            console.log(el);
            //设置配置
            let ops = {
                animation: 0,
                //拖动结束
                onEnd: function (evt) {
                    console.log(evt);
                    let oldIndex = evt.oldIndex;
                    let newIndex = evt.newIndex;
                    console.log(oldIndex, newIndex);
                    self.getDealData();
                    console.log(self.flattArray);
                    const sourceObj = self.flattArray[oldIndex];
                    const targetObj = self.flattArray[newIndex];
                    console.log(sourceObj);
                    self.changeData(sourceObj, targetObj)
                    // const currentRow = self.tableData.splice(oldIndex, 1)[0];
                    // self.tableData.splice(newIndex, 0, currentRow);
                    // let beginIndex = oldIndex < newIndex ? oldIndex :newIndex ;
                    // for (let i = beginIndex; i < self.tableData.length; i++) {
                    //     self.tableData[i].orderNum = i;
                    // }
                    console.log(self.tableData);
                },
            };
            //初始化
            this.sortable = Sortable.create(el, ops);
            console.log(this.sortable);
        },
        changeData(sourceObj, targetObj) {
            let flag = 0
            const data = Object.assign(this.tableData)
            const children = this.treeProps.children
            const rowKey = "rowKeyId"
            const func = function(arr, parent) {
                for (let i = arr.length - 1; i >= 0; i--) {
                    const item = arr[i]
                    // 判断是否是原来拖动的节点，如果是，则删除
                    if (item[rowKey] === sourceObj[rowKey] && (!parent || parent && parent[rowKey] !== targetObj[rowKey])) {
                        arr.splice(i, 1)
                        flag++
                    }
                    // 判断是否是需要插入的节点，如果是，则装入数据
                    if (item[rowKey] === targetObj[rowKey]) {
                        if (item[children]) {
                            // 判断源数据是否已经是在目标节点下面的子节点，如果是则不移动了
                            let repeat = false
                            item[children].forEach(e => {
                                if (e[rowKey] === sourceObj[rowKey]) {
                                repeat = true
                                }
                            })
                            if (!repeat) {
                                sourceObj.parentIds = []
                                item[children].unshift(sourceObj)
                            }
                        } else {
                            sourceObj.parentIds = []
                            item[children] = [sourceObj]
                        }
                        flag++
                    }
                    // 判断是否需要循环下一级，如果需要则进入下一级
                    if (flag !== 2 && item[children] && item[children].length !== 0) {
                        func(item[children], item)
                    } else if (flag === 2) {
                        break
                    }
                }
            }
            // 检测是否是将父级拖到子级下面，如果是则数据不变，界面重新回到原数据
            if (targetObj.parentIds) {
                if (targetObj.parentIds.findIndex(_ => _ === sourceObj[this.rowKey]) === -1) {
                    func(data)
                } else {
                    this.$message.error('不能将父级拖到子级下面')
                }
            } else {
                func(data)
            }
            this.$set(this, 'tableData', [])
            // 重新渲染表格，用doLayout不生效，所以重新装了一遍
            this.$nextTick(() => {
                this.$set(this, 'tableData', data)
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    /deep/ .el-table .cell{
        display: flex;
        align-items: center;
    }
    .qr {
        text-align: center;
        img {
            height: 130px;
            width: 130px;
        }
    }
    ::v-deep {
        .el-table__row .cell {
            //.el-table__expand-icon--expanded {
            //    // 这是点击后的旋转角度
            //    -webkit-transform: rotate(0deg);
            //    transform: rotate(0deg);
            //}
            //.el-table__expand-icon {
            //    .el-icon-arrow-right:before {
            //        //这是收起图标
            //        content: "\e78a";
            //        font-size: 16px;
            //        color: #1890ff;
            //    }
            //}
            //.el-table__expand-icon--expanded {
            //    .el-icon-arrow-right:before {
            //        // 这是展开图标
            //        content: "\e784";
            //        font-size: 16px;
            //        color: #1890ff;
            //    }
            //}
            //没有子节点
            //.el-table__placeholder::before {
            //    // 这是展开图标
            //    content: "\e784";
            //    font-size: 16px;
            //}
        }
    }
</style>
