<template>
    <div v-loading="mainLoading"
         :element-loading-text="mainLoadingText" class="dtlmain" ref="containDiv">
        <el-row :gutter="16" type="flex" class="row-container">
            <el-col :span="16">
                <el-form :inline="true" ref="formInline" :model="queryParams">
                    <el-form-item label="">
                        <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="queryParams.deleteStatus" placeholder="请选择">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="正常" value="0"></el-option>
                            <el-option label="已删除" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search"  @click="initCourseList">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="8" style="text-align: right">
                <el-button-group>
                    <el-button type="success" round icon="el-icon-download" @click="toDownloadQr">下载二维码</el-button>
                    <el-button type="success" round icon="el-icon-plus" @click="toCopyCource">复制课程</el-button>
                    <el-button type="success" round icon="el-icon-plus" @click="toAddCource">新增</el-button>
                </el-button-group>
            </el-col>
        </el-row>
        <el-row :gutter="16" style="height: calc(100% - 100px)" class="row-container">
            <el-col :span="24" style="height: 80%">
                <el-table
                    v-loading="tableLoading"
                    :data="tableData"
                    fit
                    stripe
                    highlight-current-row
                    row-key="chapterId"
                    style="width: 100%;"
                    ref="chaperTable"
                    :indent="20"
                    :height="height"
                >
                    <el-table-column label="章/节/知识点" prop="chapterName" min-width="250px" align="left" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="二维码" min-width="100px" align="center" :show-overflow-tooltip="true">
                        <template slot-scope="{ row }">
                            <el-popover
                                v-show="row.chapterType===3"
                                placement="right"
                                width="170"
                                trigger="click">
                                <img slot="reference" src="../../../assets/images/qrcode.png" style="width:20px;height:20px" @click="getChapterQr(row.courseId, row.chapterId)"  alt="" title="点击扫码，手机观看">
                                <div class="qr">
                                    <img :src="qrImageUrl">
                                    <p style="font-size: 13px; color: #666666">微信扫码，手机观看课程</p>
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="类型" min-width="120px" align="center">
                        <template slot-scope="{row}">
                            <el-tag :type="row.chapterType | chapterTypeColorFilter">
                                {{ row.chapterType  | chapterTypeFilter}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否免费" min-width="100px" align="center">
                        <template slot-scope="{row}">
                            <el-tag :type="row.canFree | colorFilter2">
                                {{ row.canFree  | canFreeFilter}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="排序号" prop="orderNum" min-width="100px" align="center" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="操作" align="center" width="190" fixed="right" class-name="small-padding fixed-width">
                        <template slot-scope="{row}">
                            <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEditCource(row.chapterId)">修改</el-button>
                            <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDeleteCource(row.chapterId, 1)">删除</el-button>
                            <!-- <el-button v-if="row.deleteStatus===1" type="success" plain size="mini"  icon="el-icon-refresh-right" @click="toDeleteCource(row.chapterId, 0)">恢复</el-button> -->
                            <el-button v-if="row.chapterType !== 3 && row.deleteStatus === 0" type="text" size="mini" @click="toAddChild(row.chapterId)">添加下级</el-button>
                            <el-button v-if="row.chapterType === 3 && row.deleteStatus === 0" type="text" size="mini" @click="toPlayAliyun(row.videoId)">预览</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <!-- 新增、编辑课程信息对话框 -->
        <el-dialog
            title="编辑"
            :visible.sync="editModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="700px">
                    <el-form ref="courseForm" :model="info" label-suffix=":" :rules="ruleValidateCourse" label-width="150px">
                        <el-row>
                            <el-col :span="20">
                                <el-form-item label="试题类型" prop="chapterType">
                                    <el-radio-group v-model="info.chapterType" @change="changeChapterType">
                                        <el-radio :label="1">章</el-radio>
                                        <el-radio :label="2">节</el-radio>
                                        <el-radio :label="3">知识点</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item v-show="info.chapterType===3" label="免费观看" prop="canFree">
                                    <el-radio-group v-model="info.canFree" @change="changeChapterType">
                                        <el-radio :label="0">否</el-radio>
                                        <el-radio :label="1">是</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item v-show="info.chapterType===3" label="视频" prop="videoId">
                                    <el-input v-model="info.videoName">
                                        <el-button @click="videoSelect" slot="append" icon="el-icon-search"></el-button>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="章/节/知识点名称" prop="chapterName">
                                    <el-input v-model="info.chapterName"></el-input>
                                </el-form-item>
                                <el-form-item label="所属上级" prop="parentId">
                                    <SelectTree :tree-data="chapterList" :selectId.sync="info.parentId"
                                                :treeProps="selectTreeProps" :nodeKey="'rowKeyId'"
                                                :onlySelectLeaf="false"></SelectTree>
                                </el-form-item>
                                <el-form-item label="序号" prop="orderNum">
                                    <span slot="label">
                                        序号：
                                        <el-tooltip  content="显示的顺序，不控制可输入0" effect="light" placement="top">
                                            <i class="el-icon-question" style="color:red"></i>
                                        </el-tooltip>
                                    </span>
                                        <el-input v-model="info.orderNum"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
            <span slot='footer'>
                <el-button type="text"  @click="editModal=false">取消</el-button>
                <el-button type="primary" @click="saveDataCource('courseForm')">保存</el-button>
            </span>
        </el-dialog>
        <!-- 视频列表对话框 -->
        <el-dialog
            title="视频选择"
            :visible.sync="videoSelectModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="100%"
            :fullscreen="true"
            >
            <VideoSelect @setVideoInfo="setVideoInfo"></VideoSelect>
            <!-- <video-select @setVideoInfo="setVideoInfo"></video-select> -->
        </el-dialog>
        <el-dialog
            title="课后练习"
            :visible.sync="videoExamModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="800px">
            <!-- <VideoExamItem :chapterId="currentChapterId"></VideoExamItem> -->
        </el-dialog>
        <el-dialog
            title="复制课程内容"
            :visible.sync="copyModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="800px">
            <el-row :gutter="16" style="height: 600px; overflow: auto">
                <el-row :gutter="10">
                    <el-col :span="20">
                        <el-form :label-width="100">
                            <el-form label="课程" label-width="140px" label-suffix=":">
                                <el-select
                                v-model="copyCourseId"
                                filterable
                                remote
                                :remote-method="getCourseList"
                                @change="getCourseChapterTree"
                                placeholder="请输入课程名称进行检索">
                                    <el-option
                                    v-for="item in courseList"
                                    :key="item.courseId"
                                    :label="item.courseName"
                                    :value="item.courseId">
                                    </el-option>
                                </el-select>
                            </el-form>
                        </el-form>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-alert type="warning" show-icon>复制包含章、节、知识点以及课后练习</el-alert>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="20" offset="2">
                        <el-tree
                        ref="chapterTree"
                        node-key="chapterId"
                        :data="courseChapterData"
                        :props="{label: 'chapterName', children: 'children'}"
                        :check-on-click-node="true"
                        :expand-on-click-node="false"
                        :default-checked-keys="checkedKeys"
                        show-checkbox
                        highlight-current
                        ></el-tree>
                    </el-col>
                </el-row>
            </el-row>
            <div slot='footer'>
                <el-button type="text" @click='copyModal=false'>取消</el-button>
                <el-button type="primary" @click="copySelectChapter">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="视频预览"
            :visible.sync="videoModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="700px">
            <el-row>
                <el-col>
                    <video-player  class="video-player vjs-custom-skin"
                                   ref="videoPlayer"
                                   :options="playerOptions"
                                   :playsinline="playsinline"
                    >
                    </video-player>
                </el-col>
            </el-row>
            <div slot='footer'>
                <el-button type="text"  @click="close">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import VideoSelect from './VideoSelect'
//import VideoExamItem from './VideoExamItem'
import SelectTree from '@/components/tree/SelectTree';
export default {
    components: {
        SelectTree,
        VideoSelect,
        //VideoExamItem
    },
    props: ['id'],
    filters: {
        chapterTypeFilter(status) {
            const statusMap = {
                1: '章',
                2: '节',
                3: '知识点',
            };
            return statusMap[status]
        },
        chapterTypeColorFilter(status) {
            const statusMap = {
                1: 'success',
                2: '',
                3: 'warning',
            };
            return statusMap[status]
        },
        canFreeFilter(status) {
            const statusMap = {
                0: '否',
                1: '是',
            };
            return statusMap[status]
        },
        deleteStatusFilter(status) {
            const statusMap = {
                0: '正常',
                1: '已删除',
            };
            return statusMap[status]
        },
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'danger',
            };
            return statusMap[status]
        },
        colorFilter2(status) {
            const statusMap = {
                0: 'danger',
                1: 'success',
            };
            return statusMap[status]
        },
    },
    data() {
        const validateNumber = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('输入序号'));
            } else if (!Number.isInteger(+value)) {
                callback(new Error('请输入数值'));
            } else {
                callback();
            }
        };
        return {
            mainLoading: false,
            mainLoadingText: '正在导出数据，请稍等',
            courseId: '',
            copyCourseId: '',
            checkedKeys: [],
            queryParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                keyword: '',
                deleteStatus: '0',
                isAll: 1
            },
            tableData: [],
            info: {
                orderNum: 0
            },
            selectOptions: [],
            tableLoading: false,
            editModal: false,
            copyModal: false,
            ruleValidateCourse: {
                chapterName: [
                    { required: true, message: '请输入章/节/知识点名称', trigger: 'blur' }
                ],
                chapterType: [
                    { required: true, type: 'number', message: '请选择类型', trigger: 'blur' }
                ],
                orderNum: [
                    // { required: true, message: '请输入序号', trigger: 'blur' },
                    { validator: validateNumber, trigger: 'blur' }
                    // { type: 'number', message: '请输入数字', trigger: 'blur' }
                ],
            },
            chapterList: [],
            selectTreeProps: {
                value: 'rowKeyId',
                label: 'chapterName',
                children: 'children'
            },
            height: 100,
            videoSelectModal: false,
            videoModal: false,
            playerOptions: {
                // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                aspectRatio: '16:9',
                // width: document.documentElement.clientWidth, // 默认视频全屏时的最大宽度
                autoplay: false, // 是否自动播放
                muted: false, // 是否默认静音
                language: 'zh-CN',
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                loop: false, // 导致视频一结束就重新开始。
                playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4",
                    // mp4
                    src: "",
                    // webm
                    // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
                }], // 视频地址
                poster: "", // 封面
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true  // 全屏按钮
                }
            },
            videoExamModal: false,
            currentChapterId: '',
            courseList: [],
            courseChapterData: [],
            qrImageUrl: ''
        }
    },
    watch: {
        id(val, oldVal) {
            this.courseId = val;
            this.initCourseList();
        },
    },
    computed: {
        player() {
            return this.$refs.videoPlayer.player
        },
        playsinline () {
            let ua = navigator.userAgent.toLocaleLowerCase()
            // x5内核
            if (ua.match(/tencenttraveler/) !== null || ua.match(/qqbrowse/) !== null) {
                return false
            } else {
                // ios端
                return true
            }
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.getHeight();
        });
    },
    methods: {
        init: function (courseId) {
            this.courseId = courseId;
            let data = {
                courseId: courseId,
                deleteStatus: 0,
                chapterType: ''
            };
            this.apis.postForm('course/chapter/getList', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        if (this.info.parentId) {
                            this.expandParent(this.info.parentId);
                        }
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getChapterQr: function (courseId, chapterId) {
            this.qrImageUrl = process.env.VUE_APP_API_HOST + 'notify/verify/getQr?bizType=CHAPTER&bizId=' + courseId + '&chapterId=' + chapterId + "&tenantId=" + this.appCookies.getTenantId();
        },
        getHeight () {
            let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight; // 浏览器高度
            // this.$refs.containDiv.offsetHeight
            // this.$refs.containDiv.offsetTop
            this.height = h - 255;
        },
        onDragDrop: function (a, b) {
            console.log(a, b);
            this.tableData.splice(b, 1, ...this.tableData.splice(a, 1, this.tableData[b]));
        },
        initCourseList: function () {
            let data = {
                courseId: this.courseId,
                deleteStatus: 0,
                chapterType: ''
            };
            this.apis.postForm('course/chapter/getList', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        if (this.info.parentId) {
                            this.expandParent(this.info.parentId);
                        }
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getChapterList: function () {
            let data = {
                courseId: this.courseId,
                deleteStatus: 0,
                chapterType: 1
            };
            this.apis.postForm('course/chapter/getList', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.chapterList = res.data;
                        if (this.chapterList !== null) {
                            this.chapterList.splice(0, 0, { chapterId: 0, chapterName: '请选择' });
                        } else {
                            this.chapterList = [{ chapterId: 0, chapterName: '请选择' }];
                        }
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        toAddCource: function () {
            this.info = {
                orderNum: 0,
                parentId: 0,
                canFree: 0
            };
            this.getChapterList();
            this.editModal = true;
        },
        toCopyCource: function () {
            this.courseChapterData = []
            this.copyModal = true;
        },
        toAddChild: function (chapterId) {
            this.info = {
                orderNum: 0,
                parentId: chapterId
            };
            this.getChapterList();
            this.editModal = true;
        },
        saveDataCource: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "course/chapter/save";
                    this.info.courseId = this.courseId;
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieUNAME() ;
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieUNAME() ;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editModal = false;
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.initCourseList();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        toEditCource: function (keyId) {
            this.apis.get('course/chapter/getById?chapterId=' + keyId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.info = res.data;
                        this.editModal = true;
                        this.getChapterList();
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toDeleteCource: function (id, deleteStatus) {
                let title = '确定要删除此条数据？';
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        chapterId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('course/chapter/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.initCourseList();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
        changeChapterType: function (val) {
            if (val === 1) {
                return 1
            }
        },
        expandParent: function (parentId) {
            if (parentId === undefined || parentId === '' || parentId === null) {
                return;
            }
            this.findParent(parentId, this.tableData);
        },
        findParent: function (parentId, treeData) {

            for (let i = 0; i < treeData.length; i++) {
                // 父节点查询条件
                if (treeData[i].id === parentId) {
                    // 如果找到结果,保存当前节点
                    treeData[i]._showChildren = true;
                    // console.log(treeData[i])
                    // 用当前节点再去原数据查找当前节点的父节点
                    if (treeData[i].parentId !== 0) {
                        this.findParent(treeData[i].parentId, this.tableData);
                    }
                    break;
                } else {
                    if (treeData[i].children) {
                        // console.log("treeData[i]")
                        // 没找到，遍历该节点的子节点
                        this.findParent(parentId, treeData[i].children)
                    }
                }
            }
        },
        videoSelect: function () {
            this.videoSelectModal = true;
        },
        setVideoInfo: function (videoId, videoName) {
            this.info.videoId = videoId;
            this.info.videoName = videoName;
            // eslint-disable-next-line no-undefined
            if (this.info.chapterName === undefined || this.info.chapterName === 'undefined' || this.info.chapterName === '') {
                this.info.chapterName = videoName;
            }
            this.videoSelectModal = false;
        },
        showVideo: function (videoPathUrl) {
            // this.playerOptions['sources'][0].src = this.info.videoPathUrl;
            this.playerOptions['sources'][0].src = videoPathUrl;
            this.videoModal = true;
        },
        toPlayAliyun: function (videoId) {
            this.apis.get('course/video/getById?videoId=' + videoId)
                .then((res) => {
                    if (res.code === 1) {
                        this.playerOptions['sources'][0].src = res.data.aliyunUrl;
                        this.videoModal = true;
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        videoExamList: function (id) {
            this.currentChapterId = id;
            this.videoExamModal = true;
        },
        getCourseList: function (val) {
            this.apis.get('course/info/getList?keyword=' + val)
                .then((res) => {
                    if (res.code === 1) {
                        this.courseList = res.data;
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getCourseChapterTree: function (id) {
            console.log(id)
            let data = {
                courseId: id,
                deleteStatus: 0,
                chapterType: ''
            };
            this.apis.postForm('course/chapter/getList', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.courseChapterData = res.data;
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        close: function () {
            this.$refs.videoPlayer.player.pause();
            this.videoModal=false;
        },
        //确定（复制课程）
        copySelectChapter: function () {
            // let selectArr = this.$refs.chapterTree.getCheckedKeys();
            // let chapterIds = [];
            // selectArr.forEach(function (item) {
            //         console.log(item)
            //         chapterIds.push(item.chapterId)
            // });
            let chapterIds = this.$refs.chapterTree.getCheckedKeys();
            let url = "course/info/copyChapter";
            let menuDt = {}
            menuDt.courseId = this.courseId;
            menuDt.chapterIds = chapterIds;
            menuDt.createBy = this.appCookies.getCookieUID();
            menuDt.createByname = this.appCookies.getCookieUNAME() ;
            menuDt.modifyBy = this.appCookies.getCookieUID();
            menuDt.modifyByname = this.appCookies.getCookieUNAME() ;
            // 保存数据
            this.apis.postJson(url, menuDt)
                .then((res) => {
                    if (res.code === 1) {
                        this.copyModal = false;
                        this.$message({
                            message: '保存成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.initCourseList();
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toDownloadQr: function () {
            this.mainLoading = true;
            this.mainLoadingText = '正在导出数据，请稍等';
            let data = {
                courseId: this.courseId
            };
            this.apis.axios()({
                method: 'post',
                url: 'course/chapter/downloadQr',
                data: this.$http.adornParams(data),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                if (res.type === 'application/json') {
                    this.$message({
                        message: '未查询到数据',
                        showClose: true, offset: 200, type: 'error'
                    });
                    this.mainLoading = false;
                    return false
                }
                let date = new Date();
                let year = date.getFullYear();
                let timestamp = date.valueOf();
                this.downFile(res, year.toString() + timestamp.toString() + '.zip');
                this.mainLoading = false;
            }).catch(err => {
                console.log(err);
                this.mainLoading = false;
            });
        },
        /**
         * 文件流下载
         */
        downFile(content, fileName) {
            let blob = new Blob([content], {
                type: 'application/vnd.ms-excel'
            });
            let objectUrl = URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.style.display = 'none';
            link.href = objectUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        },
    }
}
</script>

<style lang="scss" scoped>
    .qr {
        text-align: center;
        img {
            height: 130px;
            width: 130px;
        }
    }
</style>
