<template>
    <div style="height:calc(100vh - 60px);">
        <el-row :gutter="16" type="flex" class="row-container" style="height: 100%">
            <el-col :span="4">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>视频分类</span>
                    </div>
                    <GnvqTree ref="gnvqTree" @callback="toQuery"></GnvqTree>
                </el-card>
            </el-col>
            <!-- 列表主体 -->
            <el-col :span="20">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>视频列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <el-row class="query-container">
                        <el-col :span="20">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                style="width: 100%;"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="视频名称" prop="videoName" min-width="180px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="视频时长" prop="videoTime" min-width="100px" align="center"></el-table-column>
                                <el-table-column label="视频类型" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.videoType | videoTypeColorFilter">
                                            {{ row.videoType  | videoTypeFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="基础课类别" prop="baseCatName" min-width="110px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="工种" prop="gnvqName" min-width="130px" align="left"></el-table-column>
                                <el-table-column label="等级" prop="levelName" min-width="100px" align="left"></el-table-column>
                                <el-table-column label="排序号" prop="orderNum" min-width="80px" align="left"></el-table-column>
                                <el-table-column label="可用状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.useStatus | colorFilter">
                                            {{ row.useStatus  | useStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="240" fixed="right" class-name="small-padding">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.useStatus === 1" plain size="mini" @click="selectVideo(row.videoId, row.videoName)">选择</el-button>
                                        <el-button v-if="row.videoPath !== ''" plain size="mini" @click="toPlayAliyun(row)">预览</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            title="视频预览"
            :visible.sync="playVideoDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="800px"
            >
            <el-row>
                <el-col>
                    <video-player  class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :options="playerOptions"
                        :playsinline="playsinline"
                    >
                    </video-player>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="playVideoDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Pagination from '@/components/Pagination';
    import GnvqTree from "@v/components/GnvqTree";
    export default {
        components: {
            Pagination,
            GnvqTree,
            // SingleUploadImg,
            // MutiUploadImg,
            // AreaSelect
        },
        filters: {
            useStatusFilter(status) {
                const statusMap = {
                    0: '禁用',
                    1: '可用',
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    1: '已删除',
                    0: '正常',
                };
                return statusMap[status]
            },
            videoTypeFilter(status) {
                const statusMap = {
                    1: '基础课',
                    2: '专业课',
                };
                return statusMap[status]
            },
            videoTypeColorFilter(status) {
                const statusMap = {
                    1: '',
                    2: 'success'
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                //视频列表数据
                tableData: [],
                tableLoading: false,
                //视频列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    videoType: '',
                    baseCatId: '',
                    playSource: '',
                    transType: '',
                },
                //控制组件显示
                playVideoDialog: false,
                teacherDialog: false,
                uploadVideoDialog: false,
                editDrawer: false,
                //视频预览
                playerOptions: {
                    // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    aspectRatio: '16:9',
                    // width: document.documentElement.clientWidth, // 默认视频全屏时的最大宽度
                    autoplay: false, // 是否自动播放
                    muted: false, // 是否默认静音
                    language: 'zh-CN',
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    loop: false, // 导致视频一结束就重新开始。
                    playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4",
                        // mp4
                        src: "",
                        //src: "https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218114723HDu3hhxqIT.mp4"
                        // webm
                        // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
                    }], // 视频地址
                    poster: "", // 封面
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        fullscreenToggle: true  // 全屏按钮
                    }
                },
            };
        },
        mounted () {
            this.initTable();
        },
        computed: {
            //视频预览相关
            player() {
                return this.$refs.videoPlayer.player
            },
            playsinline () {
                let ua = navigator.userAgent.toLocaleLowerCase()
                // x5内核
                if (ua.match(/tencenttraveler/) !== null || ua.match(/qqbrowse/) !== null) {
                    return false
                } else {
                    // ios端
                    return true
                }
            }
        },
        methods: {
            selectVideo: function (videoId, videoName) {
                this.$emit('setVideoInfo', videoId, videoName);
            },
            //获取视频列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('course/video/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            //gnvqTree组件（视频分类）callback方法
            toQuery: function (data) {
                // console.log(data)
                this.queryParams.videoType = 2
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                if (data.type === 'CAT') {
                    this.queryParams.gnvqCatId = data.id;
                } else if (data.type === 'INFO') {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.id;
                } else {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.parentId;
                    this.queryParams.levelId = data.id;
                }
                this.refreshTable();
            },
            //视频预览
            toPlayAliyun: function (row) {
                this.apis.get('course/video/getById?videoId=' + row.videoId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.showVideoAli(res.data.aliyunUrl);
                            this.videoId = res.data.videoId;
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            showVideoAli: function (videoPathUrl) {
                //this.playerOptions['sources'][0].src = "https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218114723HDu3hhxqIT.mp4"
                //this.playerOptions['sources'][0].src = this.info.videoPathUrl;
                this.playerOptions['sources'][0].src = videoPathUrl;
                this.playVideoDialog = true;
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
